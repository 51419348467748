import SidebarComponent from "../../components/Sidebar";
import NavbarComponent from "../../components/Navbar";
import React, { Component } from "react";
import { POST, tokens, baht, total, report_transfer, alert, format_date } from "../../components/CustomComponent.js";
import "../../assets/css/bootstrap.css";
export default class ReportTransferScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      loading: false,

      data: [],
      data_year: [],

      year: "",
      start_date: "",
      end_date: "",
    };
  }
  componentDidMount() {
    let year = [];
    for (let i = 0; i < 10; i++) {
      year.push(new Date().getFullYear() - i);
    }
    this.setState({ data_year: year });
  }
  GetAll = async () => {
    this.setState({ loading: true });

    if (this.state.start_date === "" && this.state.end_date !== "") {
      alert("warning", "แจ้งเตือน", "กรุณาเลือกวันที่เริ่มต้น");
      this.setState({ loading: false, end_date: "" });
      return;
    }

    let body = {
      year: this.state.year ? Number(this.state.year) : "",
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    };
    let result = await POST(tokens, "v1/report/transfer/summary/page/0", body);
    if (result && result.status) {
      console.log("result.data.data:",result.data.data)
      this.setState({ data: result.data.data });
    }
    this.setState({ loading: false });
  };

  HandlePageChange(pageNumber) {
    this.setState({ page: pageNumber });
    setTimeout(() => {
      this.GetAll();
    }, 10);
  }

  ClearForm() {
    this.setState({
      page: 1,
      data: [],
      start_date: "",
      end_date: "",
      year: "",
    });
  }
  render() {
    return (
      <div className="full bg-contain w-100">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <div className="menu-container">
          <SidebarComponent />
          <div className="bodyw-100">
            <NavbarComponent page1="หน้าหลัก" page2="รายการโอน" />
            <div style={{ height: 60 }}></div>
            <div className="w-100 row mx-0 d-flex align-items-center justify-content-between px-3 mt-3">
              <h3>
                <b>รายการโอน</b>
              </h3>
              <div>
                <button
                  type="button"
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    if (this.state.data.length === 0) {
                      alert("warning", "แจ้งเตือน", "กรุณาเลือกข้อมูลที่ต้องการออกรายงาน");
                      return;
                    }
                    report_transfer(this.state.data, this.state.start_date, this.state.end_date, this.state.year);
                  }}
                >
                  <span className="icon-brand text-18 ">{"\uf6dd"}</span> ออกรายงาน
                </button>
              </div>
            </div>
            <div className="w-100 p-3">
              <div className="card">
                <div className="card-header row d-flex mx-0 px-2 rounded-top-16">
                  <div className="d-flex row mx-0 justify-content-between w-100">
                    <div className="d-flex row mx-0 align-items-center">
                      <select
                        className="form-control-select wpx-180 mx-2 mb-2 pointer"
                        onChange={(e) => {
                          this.setState({
                            year: e.target.value,
                          });
                        }}
                        value={this.state.year}
                      >
                        <option value="0">เลือกปี</option>
                        {this.state.data_year.map((item, index) => (
                          <option value={item}>ปี {item + 543}</option>
                        ))}
                      </select>
                      <input
                        type="date"
                        className="form-control-select wpx-180 mx-2 mb-2 pointer"
                        placeholder="วันที่เริ่มต้น"
                        onChange={(e) => {
                          this.setState({
                            start_date: e.target.value,
                          });
                        }}
                        value={this.state.start_date}
                      ></input>
                      <label className="mx-2 mb-2">ถึง</label>
                      <input
                        type="date"
                        className="form-control-select wpx-180 mx-2 mb-2 pointer"
                        placeholder="วันที่สิ้นสุด"
                        onChange={(e) => {
                          const date_start_date = new Date(this.state.start_date).getTime();
                          const date_end_date = new Date(e.target.value).getTime();

                          if (date_start_date <= date_end_date) {
                            this.setState({ end_date: e.target.value });
                          }
                        }}
                        value={this.state.end_date}
                      ></input>
                      <button
                        type="button"
                        className="btn btn-primary ml-2 mb-1 "
                        onClick={() => {
                          this.GetAll();
                        }}
                      >
                        {" "}
                        ค้นหา
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary bg-white mx-2 mb-2 pointer"
                        onClick={() => {
                          this.ClearForm();
                        }}
                      >
                        <span className="icon-brand text-18 pointer">{"\uf2f9"}</span> รีเซ็ท
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive rounded-16">
                    <table className="table table-striped table-borderless table-sm ">
                      <thead>
                        <tr>
                          <th className="text-left" style={{ minWidth: 130 }}>
                            <b>วันที่โอน</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 130 }}>
                            <b>ชื่อลูกค้า</b>
                          </th>
                          <th className="text-left">
                            <b>เซ็นชื่อ</b>
                          </th>
                          <th className="text-right">
                            <b>ยอดโอนเงิน</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 100 }}>
                            <b>ชื่อบัญชี</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 100 }}>
                            <b>เลข Invoice</b>
                          </th>
                          <th className="text-left" style={{ minWidth: 100 }}>
                            <b>ใบแจ้งหนี้</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 70 }}>
                            <b>เช็นตัด</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 70 }}>
                            <b>เช็คบิล</b>
                          </th>
                          <th className="text-center" style={{ minWidth: 70 }}>
                            <b>หมายเหตุ</b>
                          </th>
                        </tr>
                      </thead>
                      {((this.state.data && this.state.data.length === 0) || !this.state.data) && (
                        <tbody>
                          <tr>
                            <td colSpan={9} className="text-center table-light">
                              -- ไม่มีข้อมูล --
                            </td>
                          </tr>
                        </tbody>
                      )}
                      {this.state.data &&
                        this.state.data.map((item, index) => (
                          <tbody>
                            <tr key={index} className="table-light">
                              <td className="text-left">{format_date(item.record_date)}</td>
                              <td className="text-left">{item.customer_name}</td>
                              <td className="text-left">{item.sign_name}</td>
                              <td className="text-right">{baht(item.transfer_amount)}</td>
                              <td className="text-left">
                                {item.bank_name}
                                <br />
                                {item.bank_number}
                              </td>
                              <td className="text-left">{item.invoice_number}</td>
                              <td className="text-left">{item.track_invoice ? item.track_invoice : ""}</td>
                              <td className="text-center">{item.sign_cut}</td>
                              <td className="text-center">{item.check_bill}</td>
                              <td className="text-left">
                                {item.remark_payment_s3 ? item.remark_payment_s3 : ""} {item.payment_remark ? item.payment_remark : ""}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      <tfoot>
                        <td className="text-left">
                          <b>ยอดรวม</b>
                        </td>
                        <td className="text-left"></td>
                        <td className="text-right">
                          <b>{baht(total(this.state.data, "transfer_amount"))} บาท</b>
                        </td>
                        <td className="text-left"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                        <td className="text-center"></td>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
