/* eslint-disable no-mixed-operators */
import Swal from "sweetalert2";
import Cookies from "universal-cookie";

import { Modal } from "react-bootstrap";
import React, { Component } from "react";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "pdfmake/build/vfs_fonts";

import "../assets/css/bootstrap.css";
import "./global.js";

import CORRECT from "../../src/assets/images/correct.png";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  FCIconic: {
    normal: "FCIconic-Regular.ttf",
    bold: "FCIconic-Bold.ttf",
    italics: "FCIconic-Regular.ttf",
    bolditalics: "FCIconic-Regular.ttf",
  },
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
};
const cookies = new Cookies();
const tokens = localStorage.getItem("token_AHJ_test");
const role = JSON.parse(localStorage.getItem("role"));
const number_zero = (number, length) => {
  return length ? number.toString().padStart(length, "0") : number.toString().padStart(2, "0");
};
const format_date2 = (date, format, locale) => {
  if (date === "None") {
    return null;
  }
  let array_month = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
  let array_full_month = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
  let new_date = new Date(date);
  let full_date = "-";
  let day = new_date.getDate();
  let month = new_date.getMonth();
  let year = new_date.getFullYear();
  let hour = new_date.getHours();
  let minute = new_date.getMinutes();
  let second = new_date.getSeconds();
  if (locale) {
    if (locale.toLowerCase() === "en") {
      array_month = ["Jan.", "Feb.", "Mar.", "Apr.", "May.", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];
      array_full_month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      year = new_date.getFullYear() > 2100 ? new_date.getFullYear() - 543 : new_date.getFullYear();
    } else {
      year = new_date.getFullYear() > 2100 ? new_date.getFullYear() : new_date.getFullYear() + 543;
    }
  } else {
    year = new_date.getFullYear() > 2100 ? new_date.getFullYear() : new_date.getFullYear() + 543;
  }
  if (format) {
    format = format.toLowerCase();
    // TIME
    if (format.includes("hh:mm:ss")) {
      format = format.replaceAll("hh:mm:ss", number_zero(hour) + ":" + number_zero(minute) + ":" + number_zero(second));
    } else if (format.includes("hh:mm")) {
      format = format.replaceAll("hh:mm", number_zero(hour) + ":" + number_zero(minute));
    } else if (format.includes("hh")) {
      format = format.replaceAll("hh:mm", number_zero(hour));
    } else if (format.includes("h:m:s")) {
      format = format.replaceAll("h:m:s", hour + ":" + minute + ":" + second);
    } else if (format.includes("h:m")) {
      format = format.replaceAll("h:m", hour + ":" + minute);
    }
    // DAY
    if (format.includes("dd")) {
      format = format.replaceAll("dd", number_zero(day));
    } else {
      format = format.replaceAll("d", day);
    }
    // MONTH
    if (format.includes("mmmm")) {
      format = format.replaceAll("mmmm", array_full_month[month]);
    } else if (format.includes("mmm")) {
      format = format.replaceAll("mmm", array_month[month]);
    } else if (format.includes("mm")) {
      format = format.replaceAll("mm", number_zero(month + 1));
    } else if (format.includes("m")) {
      format = format.replaceAll("m", month + 1);
    }
    // YEAR
    if (format.includes("yyyy")) {
      format = format.replaceAll("yyyy", number_zero(year, 4));
    } else if (format.includes("yyy")) {
      format = format.replaceAll("yyy", year.toString().slice(-3));
    } else if (format.includes("yy")) {
      format = format.replaceAll("yy", year.toString().slice(-2));
    } else if (format.includes("y")) {
      format = format.replaceAll("y", year);
    }
    full_date = format;
  } else {
    full_date = number_zero(day) + "/" + number_zero(month + 1) + "/" + number_zero(year, 4);
  }
  return full_date;
};
const format_date = (date) => {
  const array_month = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
  if (date) {
    if (date.includes("T")) {
      let datetimes = date.split("T")[0].split("-")[2] + " " + array_month[Number(date.split("T")[0].split("-")[1]) - 1] + " " + (Number(date.split("T")[0].split("-")[0]) + 543).toString().slice(2, 4) + ", " + date.split("T")[1].slice(0, 5) + " น.";
      return datetimes;
    } else if (date.includes("-")) {
      let datetimes = date.split("-")[2].split("/")[0] + "/" + ("0" + Number(date.split("-")[1].split("/")[0])).toString().slice(-2) + "/" + (Number(date.split("T")[0].split("-")[0]) + 543).toString().slice(2, 4);
      return datetimes;
    } else {
      let datetimes = date.split("-")[2].split("/")[0] + " " + array_month[Number(date.split("-")[1].split("/")[0]) - 1] + " " + date.split("-")[0].split("/")[0];
      return datetimes;
    }
  } else {
    return "-";
  }
};
const format_date_report = (date) => {
  if (date) {
    if (date.includes("-")) {
      let datetimes = date.split("-")[2].split("/")[0] + "/" + ("0" + Number(date.split("-")[1].split("/")[0])).toString().slice(-2) + "/" + (Number(date.split("T")[0].split("-")[0]) + 543).toString();
      return datetimes;
    }
  } else {
    return "-";
  }
};
const format_date_sale = (date) => {
  const array_month = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
  if (date) {
    if (date.includes(" ")) {
      let datetimes = date.split("/")[0] + " " + array_month[Number(date.split("/")[1]) - 1] + " " + (Number(date.split("/")[2].split(" ")[0]) + 543).toString().slice(2, 4) + ", " + date.split("/")[2].split(" ")[1] + " น.";
      return datetimes;
    }
  } else {
    return "-";
  }
};
const format_duedate_sale = (date) => {
  const array_month = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];

  if (date.includes("-")) {
    let datetimes = date.split("-")[2].split("/")[0] + " " + array_month[Number(date.split("-")[1].split("/")[0]) - 1] + " " + (Number(date.split("-")[0].split("/")[0]) + 543).toString().slice(2, 4);
    return datetimes;
  } else {
    let datetimes = date.split("/")[0] + " " + array_month[Number(date.split("/")[1]) - 1] + " " + (Number(date.split("/")[2].split(" ")[0]) + 543).toString().slice(2, 4);
    return datetimes;
  }
};
const format_date_main = (date) => {
  const array_month = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];

  if (date) {
    date = new Date(date);
    let datetimes = date.getDate() + " " + array_month[date.getMonth()] + " " + (date.getFullYear() + 543);
    return datetimes;
  } else {
    return "-";
  }
};
const date = (date) => {
  const array_month = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
  let datetimes = date.split(" ")[0].split("-")[2] + " " + array_month[Number(date.split(" ")[0].split("-")[1]) - 1] + " " + (Number(date.split(" ")[0].split("-")[0]) + 543) + ", " + date.split(" ")[1].slice(0, 5) + " น.";
  return datetimes;
};
const format_date_notime = (date) => {
  const array_month = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
  if (date) {
    if (date.includes("T")) {
      let datetimes = date.split("T")[0].split("-")[2] + " " + array_month[Number(date.split("T")[0].split("-")[1]) - 1] + " " + (Number(date.split("T")[0].split("-")[0]) + 543).toString().slice(2, 4);
      return datetimes;
    } else {
      let datetimes = date.split("-")[2] + "/" + date.split("-")[1] + "/" + (Number(date.split("-")[0]) + 543);
      return datetimes;
    }
  } else {
    return "-";
  }
};
const date_invoice = (date) => {
  const array_month = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
  let datetimes = date.split("/")[0] + " " + array_month[Number(date.split("/")[1]) - 1] + " " + (Number(date.split("/")[2].split("/")[0]) + 543).toString().slice(2, 4);
  return datetimes;
};
const date_invoice_s = (date) => {
  const array_month = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
  let datetimes = date.split(" ")[0] + " " + array_month[Number(date.split(" ")[1]) - 1] + " " + date.split(" ")[2].slice(0, 8) + " น.";
  return datetimes;
};
const date_overdue = (date, countdown) => {
  const array_month = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];

  let datetimes = "";
  try {
    datetimes = date.getDate() + " " + array_month[date.getMonth()] + " " + (date.getFullYear() + 543) + ", " + date.getHours() + ":" + date.getMinutes() + " น.";
  } catch (e) {
    if (Number(countdown) > 0) {
      datetimes = date.split("-")[2] + " " + array_month[Number(date.split("-")[1]) - 1] + " " + (Number(date.split("-")[0]) + 543) + " (อีก " + countdown + " วัน)";
    } else {
      datetimes = date.split("-")[2] + " " + array_month[Number(date.split("-")[1]) - 1] + " " + (Number(date.split("-")[0]) + 543) + " (เกินกำหนด " + Number(countdown) * -1 + " วัน)";
    }
  }
  return datetimes;
};
async function validate(state, type) {
  for (let value of state[type]) {
    if (state[value] === "") {
      alert("warning", "แจ้งเตือน", message(value));
      return null;
    }
    if (value.includes("phone") && state[value].length < 9) {
      alert("warning", "แจ้งเตือน", message(value + "_length"));
      return null;
    }
    if (value.includes("phone") && !state[value].match(/^^[0][0-9]*$/)) {
      alert("warning", "แจ้งเตือน", message(value + "_else"));
      return null;
    }
    if (value.includes("email") && !state[value].match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      alert("warning", "แจ้งเตือน", message(value + "_else"));
      return null;
    }
    if (value.includes("tax_invoice") && state[value].length < 13) {
      alert("warning", "แจ้งเตือน", message(value + "_length"));
      return null;
    }
    // if (value.includes("banknumber") && !(state[value]).match(/^^[0][0-9]*$/)) {

    // }
  }
  return true;
}
async function gen_Password(count) {
  let result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < count; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}
const success = () => {
  return Swal.fire({
    icon: "success",
    title: "สำเร็จ",
    text: "การทำรายการสำเร็จแล้ว",
    confirmButtonText: "ตกลง",
  });
};
const alert = (icon, title, detail) => {
  return Swal.fire({
    icon: icon,
    title: title,
    text: detail,
    confirmButtonText: "ตกลง",
  });
};
const alert_url = (icon, title, detail, url) => {
  return Swal.fire({
    icon: icon,
    title: title,
    text: detail,
    confirmButtonText: "ตกลง",
  }).then(() => {
    window.location.href = url;
  });
};
function message(message) {
  if (message.product_name) {
    message = message.product_name[0];
  }
  let text = global.message[message];
  if (message === "Token is expired" || message === "Token is not found") {
    localStorage.clear();

    window.location.href = "/";
    return null;
  }
  text = text ? text : "เกิดข้อผิดพลาด";
  return text;
}
async function GET(token, url, body) {
  let promise = new Promise(async function (resolve, reject) {
    let header = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: token,
      },
      // cache: "no-cache"
    };
    let text_body = "";
    if (body) {
      let array_body = Object.entries(body);
      text_body += "?";
      for (let bd of array_body) {
        text_body += bd[0] + "=";
        text_body += bd[1] + "&";
      }
      text_body = text_body.slice(0, -1);
    }
    await fetch(global.api + url + text_body, header)
      .then((result) => result.json())
      .then((result) => {
        if (result.status) {
          resolve(result);
        } else {
          if (result.detail) {
            alert("warning", "แจ้งเตือน", message(result.detail));
          } else if (result.data.message === "Role no access permission") {
            // localStorage.clear()
            // window.location.href = "/"
            // return null;
            alert("warning", "แจ้งเตือน", "ไม่มีสิทธิ์เข้าถึง");
          } else if (result.data.message) {
            alert("warning", "แจ้งเตือน", message(result.data.message));
          } else {
            alert("warning", "แจ้งเตือน", message(result.message));
          }
          resolve(null);
        }
      })
      .catch((e) => {
        alert("warning", "แจ้งเตือน", "เกิดข้อผิดพลาด");
        resolve(null);
      });
  });
  return promise;
}
async function GET_CACHE(token, url, body) {
  let promise = new Promise(async function (resolve, reject) {
    let header = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: token,
      },
      cache: "no-cache",
    };
    let text_body = "";
    if (body) {
      let array_body = Object.entries(body);
      text_body += "?";
      for (let bd of array_body) {
        text_body += bd[0] + "=";
        text_body += bd[1] + "&";
      }
      text_body = text_body.slice(0, -1);
    }
    await fetch(global.api + url + text_body, header)
      .then((result) => result.json())
      .then((result) => {
        if (result.status) {
          resolve(result);
        } else {
          if (result.detail) {
            alert("warning", "แจ้งเตือน", message(result.detail));
          } else if (result.data.message === "Role no access permission") {
            // localStorage.clear()
            // window.location.href = "/"
            // return null;
            alert("warning", "แจ้งเตือน", "ไม่มีสิทธิ์เข้าถึง");
          } else if (result.data.message) {
            alert("warning", "แจ้งเตือน", message(result.data.message));
          } else {
            alert("warning", "แจ้งเตือน", message(result.message));
          }
          resolve(null);
        }
      })
      .catch((e) => {
        alert("warning", "แจ้งเตือน", "เกิดข้อผิดพลาด");
        resolve(null);
      });
  });
  return promise;
}
async function POST(token, url, body) {
  let promise = new Promise(async function (resolve, reject) {
    let header = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    };
    await fetch(global.api + url, header)
      .then((result) => result.json())
      .then((result) => {
        if (result.status) {
          resolve(result);
        } else {
          if (result.detail) {
            alert("warning", "แจ้งเตือน", message(result.detail));
          } else if (result.data.message === "Role no access permission") {
            // localStorage.clear()
            // window.location.href = "/"
            // return null;
            alert("warning", "แจ้งเตือน", "ไม่มีสิทธิ์เข้าถึง");
          } else if (result.data.message === "order main number is not found") {
            resolve("getOM");
          } else if (result.data.message) {
            alert("warning", "แจ้งเตือน", message(result.data.message));
          } else {
            alert("warning", "แจ้งเตือน", message(result.message));
          }
          resolve(null);
        }
      })
      .catch((e) => {
        alert("warning", "แจ้งเตือน", "เกิดข้อผิดพลาด");
        resolve(null);
      });
  });
  return promise;
}
async function POST_CACHE(token, url, body) {
  let promise = new Promise(async function (resolve, reject) {
    let header = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
      cache: "no-cache",
    };
    await fetch(global.api + url, header)
      .then((result) => result.json())
      .then((result) => {
        if (result.status) {
          resolve(result);
        } else {
          if (result.detail) {
            alert("warning", "แจ้งเตือน", message(result.detail));
          } else if (result.data.message === "Role no access permission") {
            // localStorage.clear()
            // window.location.href = "/"
            // return null;
            alert("warning", "แจ้งเตือน", "ไม่มีสิทธิ์เข้าถึง");
          } else if (result.data.message === "order main number is not found") {
            resolve("getOM");
          } else if (result.data.message) {
            alert("warning", "แจ้งเตือน", message(result.data.message));
          } else {
            alert("warning", "แจ้งเตือน", message(result.message));
          }
          resolve(null);
        }
      })
      .catch((e) => {
        alert("warning", "แจ้งเตือน", "เกิดข้อผิดพลาด");
        resolve(null);
      });
  });
  return promise;
}
async function PUT(token, url, body) {
  let promise = new Promise(async function (resolve, reject) {
    let header = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    };
    await fetch(global.api + url, header)
      .then((result) => result.json())
      .then((result) => {
        if (result.status) {
          resolve(result);
        } else {
          if (result.detail) {
            alert("warning", "แจ้งเตือน", message(result.detail));
          } else if (result.data.message === "Role no access permission") {
            // localStorage.clear()
            // window.location.href = "/"
            // return null;
            alert("warning", "แจ้งเตือน", "ไม่มีสิทธิ์เข้าถึง");
          } else if (result.data.message === "order main number is not found") {
            resolve("getOM");
          } else if (result.data.message) {
            alert("warning", "แจ้งเตือน", message(result.data.message));
          } else {
            alert("warning", "แจ้งเตือน", message(result.message));
          }
          resolve(null);
        }
      })
      .catch((e) => {
        alert("warning", "แจ้งเตือน", "เกิดข้อผิดพลาด");
        resolve(null);
      });
  });
  return promise;
}
async function PUT_CACHE(token, url, body) {
  let promise = new Promise(async function (resolve, reject) {
    let header = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
      cache: "no-cache",
    };
    await fetch(global.api + url, header)
      .then((result) => result.json())
      .then((result) => {
        if (result.status) {
          resolve(result);
        } else {
          if (result.detail) {
            alert("warning", "แจ้งเตือน", message(result.detail));
          } else if (result.data.message === "Role no access permission") {
            // localStorage.clear()
            // window.location.href = "/"
            // return null;
            alert("warning", "แจ้งเตือน", "ไม่มีสิทธิ์เข้าถึง");
          } else if (result.data.message === "order main number is not found") {
            resolve("getOM");
          } else if (result.data.message) {
            alert("warning", "แจ้งเตือน", message(result.data.message));
          } else {
            alert("warning", "แจ้งเตือน", message(result.message));
          }
          resolve(null);
        }
      })
      .catch((e) => {
        alert("warning", "แจ้งเตือน", "เกิดข้อผิดพลาด");
        resolve(null);
      });
  });
  return promise;
}
async function DELETE(token, url, body) {
  let promise = new Promise(async function (resolve, reject) {
    let header = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: token,
      },
    };
    await fetch(global.api + url, header)
      .then((result) => result.json())
      .then((result) => {
        if (result.status) {
          resolve(result);
        } else {
          if (result.detail) {
            alert("warning", "แจ้งเตือน", message(result.detail));
          } else if (result.data.message === "Role no access permission") {
            // localStorage.clear()
            // window.location.href = "/"
            // return null;
            alert("warning", "แจ้งเตือน", "ไม่มีสิทธิ์เข้าถึง");
          } else if (result.data.message) {
            alert("warning", "แจ้งเตือน", message(result.data.message));
          } else {
            alert("warning", "แจ้งเตือน", message(result.message));
          }
          resolve(null);
        }
      })
      .catch((e) => {
        alert("warning", "แจ้งเตือน", "เกิดข้อผิดพลาด");
        resolve(null);
      });
  });
  return promise;
}
async function DELETE_CACHE(token, url, body) {
  let promise = new Promise(async function (resolve, reject) {
    let header = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: token,
      },
      cache: "no-cache",
    };
    await fetch(global.api + url, header)
      .then((result) => result.json())
      .then((result) => {
        if (result.status) {
          resolve(result);
        } else {
          if (result.detail) {
            alert("warning", "แจ้งเตือน", message(result.detail));
          } else if (result.data.message === "Role no access permission") {
            // localStorage.clear()
            // window.location.href = "/"
            // return null;
            alert("warning", "แจ้งเตือน", "ไม่มีสิทธิ์เข้าถึง");
          } else if (result.data.message) {
            alert("warning", "แจ้งเตือน", message(result.data.message));
          } else {
            alert("warning", "แจ้งเตือน", message(result.message));
          }
          resolve(null);
        }
      })
      .catch((e) => {
        alert("warning", "แจ้งเตือน", "เกิดข้อผิดพลาด");
        resolve(null);
      });
  });
  return promise;
}
async function logout() {
  try {
    let result = await GET(tokens, "v1/logout", null);
    if (result && result.status) {
      localStorage.clear();

      cookies.remove("token_AHJ_test");
      cookies.remove("role");
      cookies.remove("profile");
      cookies.remove("permission_page");
      cookies.remove("status_bill");
      cookies.remove("bill");
      cookies.remove("user_code");

      window.location.href = "/";
    }
  } catch (error) {
    localStorage.clear();

    cookies.remove("token_AHJ_test");
    cookies.remove("role");
    cookies.remove("profile");
    cookies.remove("permission_page");
    cookies.remove("status_bill");
    cookies.remove("bill");
    cookies.remove("user_code");

    window.location.href = "/";
  }
}
const status_stock = (status) => {
  let style = "";
  let text = "";
  switch (status) {
    case 3:
      text = "รับสินค้า";
      style = "bg-green-light text-white text-12 px-2 rounded";
      break;
    case 2:
      text = "ปรับสต๊อก";
      style = "bg-yellow-dark text-dark  text-12 px-2 rounded";
      break;
    case 1:
      text = "ย้ายสต๊อก";
      style = "btn-button-danger text-white  text-12 px-2 rounded";
      break;
    default:
      text = "";
      style = "";
      break;
  }
  return <span className={style}> {text} </span>;
};
const status_delivery = (status) => {
  let style = "";
  let text = "";
  switch (status) {
    case 1:
      text = "ยังไม่ได้ส่ง";
      style = "bg-grey-type text-white text-12 px-2 rounded";
      break;
    case 2:
      text = "อยู่ระหว่างการขนส่ง";
      style = "bg-primary-light text-white text-12 px-2 rounded";

      break;
    case 3:
      text = "ส่งแล้ว(ยังไม่ได้รับเงิน)";
      style = "bg-yellow-light text-dark  text-12 px-2 rounded";
      break;
    case 4:
      text = "ส่งแล้ว";
      style = "bg-green-light2 text-white  text-12 px-2 rounded";
      break;
    default:
      text = "";
      style = "";
      break;
  }
  return <span className={style}> {text} </span>;
};
const document_type = (status) => {
  let style = "";
  let text = "";
  switch (status) {
    case 5:
      text = "ใบแจ้งหนี้";
      style = "bg-orange text-white  text-12 px-2 rounded";
      break;
    case 2:
      text = "invoice";
      style = "bg-content-2 text-white  text-12 px-2 rounded";
      break;
    default:
      text = "";
      style = "";
      break;
  }
  return <span className={style}> {text} </span>;
};
const status_adjustment = (status) => {
  let style = "";
  let text = "";
  let icon = "";
  switch (status) {
    case 1:
      icon = "\uf058";
      text = "Approved";
      style = "text-green-adjustment text-12 icon ";
      break;
    case 2:
      icon = "\uf057";
      text = "Reject";
      style = "text-danger text-12 icon";
      break;
    case 0:
      icon = "\uf017";
      text = "Pending...";
      style = "text-yellow-adjustment text-12 icon";
      break;
    default:
      text = "";
      style = "";
      break;
  }
  return (
    <span className={style}>
      {icon} {text}
    </span>
  );
};
const status_sale_transaction = (status) => {
  let style = "";
  let text = "";
  switch (status) {
    case 1:
      text = "ใบเสนอราคา";
      style = "bg-purple text-white  text-12 px-2 rounded";
      break;
    case 2:
      text = "เปิดบิล";
      style = "bg-primary-light text-white  text-12 px-2 rounded";
      break;
    case 3:
      text = "ปิดบิล";
      style = "bg-green-dark text-white  text-12 px-2 rounded";
      break;
    case 4:
      text = "เกินกำหนด";
      style = "bg-orange-dark text-white  text-12 px-2 rounded";
      break;
    case 5:
      text = "เกินกำหนด/ใบแจ้งหนี้";
      style = "bg-orange text-white  text-12 px-2 rounded";
      break;
    case 6:
      text = "ยกเลิก";
      style = "bg-danger-light text-white text-12 px-2 rounded";
      break;
    case 7:
      text = "รอดำเนินการ";
      style = "bg-yellow-dark text-dark text-12 px-2 rounded";
      break;
    default:
      text = "";
      style = "";
      break;
  }
  return <span className={style}>{text}</span>;
};
const status_sale_transaction2 = (status) => {
  let style = "";
  let text = "";
  switch (status) {
    case 1:
      text = "ใบเสนอราคา";
      style = "bg-purple text-white  text-12 px-2 rounded";
      break;
    case 2:
      text = "เปิดบิล";
      style = "bg-primary-light text-white  text-12 px-2 rounded";
      break;
    case 3:
      text = "ปิดบิล";
      style = "bg-green-dark text-white  text-12 px-2 rounded";
      break;
    case 4:
      text = "เกินกำหนด";
      style = "bg-orange-dark text-white  text-12 px-2 rounded";
      break;
    case 5:
      text = "เกินกำหนด / ใบแจ้งหนี้";
      style = "bg-orange text-white text-12 px-2 rounded";
      break;
    case 6:
      text = "ยกเลิก";
      style = "bg-danger-light text-white text-12 px-2 rounded";
      break;
    case 7:
      text = "รอดำเนินการ";
      style = "bg-yellow-dark text-dark text-12 px-2 rounded";
      break;
    default:
      text = "";
      style = "";
      break;
  }
  return <span className={style}>{text}</span>;
};
const status_invoice = (status) => {
  let style = "";
  let text = "";
  switch (status) {
    case 1:
      text = "ยังไม่ได้ชำระ";
      style = "bg-yellow-light text-dark  text-12 px-2 rounded";
      break;
    case 2:
      text = "ชำระแล้ว";
      style = "bg-green-dark text-white  text-12 px-2 rounded";
      break;
    case 3:
      text = "เกินกำหนด";
      style = "bg-orange-dark text-white  text-12 px-2 rounded";
      break;
    default:
      text = "";
      style = "";
      break;
  }
  return <span className={style}>{text}</span>;
};
const status_type_expenses = (status) => {
  let style = "";
  let text = "";
  switch (status) {
    case 0:
      text = "";
      style = "";
      break;
    case 1:
      text = "ค่าใช้จ่าย";
      style = "bg-status-stype-one text-white text-12 px-2 rounded";
      break;
    case 2:
      text = "ปรับสต๊อก";
      style = "bg-status-stype-two text-white text-12 px-2 rounded";
      break;
    case 3:
      text = "ส่วนลด";
      style = "bg-green-light2 text-white text-12 px-2 rounded";
      break;
    default:
      text = "";
      style = "";
      break;
  }
  return <span className={style}>{text}</span>;
};
const status_payment = (status) => {
  let style = "";
  let text = "";
  switch (status) {
    case 1:
      text = "รอชำระเงิน";
      style = "bg-yellow-light text-dark  text-12 px-2 rounded";
      break;
    case 2:
      text = "ชำระเงินแล้ว";
      style = "bg-green-dark text-white  text-12 px-2 rounded";
      break;
    case "ชำระแล้ว":
      text = "ชำระแล้ว";
      style = "bg-green-dark text-white  text-12 px-2 rounded";
      break;
    case 3:
      text = "ยกเลิก (คืนสินค้า)";
      style = "bg-dark text-white text-12 px-2 rounded";
      break;
    default:
      text = "";
      style = "";
      break;
  }
  return <span className={style}>{text}</span>;
};
const type_user = (status) => {
  let style = "";
  let text = "";
  switch (status) {
    case "emp_sale":
      text = "พนักงานขาย";
      style = "bg-grey-type text-white  text-12 px-2 rounded border border-light";
      break;
    case "emp_stock":
      text = "พนักงานสต๊อก";
      style = "bg-grey-type text-white  text-12 px-2 rounded border border-light";
      break;
    case "emp_delivery":
      text = "พนักงานสายส่ง";
      style = "bg-grey-type text-white  text-12 px-2 rounded border border-light";
      break;
    case "manager":
      text = "ผู้จัดการ";
      style = "bg-grey-type text-white  text-12 px-2 rounded border border-light";
      break;
    case "owner":
      text = "เจ้าของร้าน";
      style = "bg-grey-type text-white  text-12 px-2 rounded border border-light";
      break;
    default:
      text = "";
      style = "";
      break;
  }
  return <span className={style}>{text}</span>;
};
const type_member = (status) => {
  let style = "";
  let text = "";
  switch (status) {
    case "store_technician":
      text = "ช่างเย็บ";
      style = "bg-primary-light text-white text-12 px-2 rounded border border-light";
      break;
    case "store_normol":
      text = "ลูกค้าทั่วไป";
      style = "bg-yellow-dark text-dark text-12 px-2 rounded border border-light";
      break;
    case "store_wholesale":
      text = "ร้านค้าช่วง";
      style = "bg-orange-dark text-white text-12 px-2 rounded border border-light";
      break;
    case "store_school":
      text = "ลูกค้าโรงเรียน";
      style = "bg-green-dark text-white text-12 px-2 rounded border border-light";
      break;
    default:
      text = "";
      style = "";
      break;
  }
  return <span className={style}>{text}</span>;
};
const status_pos_payment = (status) => {
  let style = "";
  let text = "";
  switch (status) {
    case 1:
      text = "รอชำระเงิน";
      style = "bg-cash-1 text-white  text-12 px-2 rounded";
      break;
    case 2:
      text = "ชำระเงินแล้ว";
      style = "bg-green-dark text-white  text-12 px-2 rounded";
      break;
    case 3:
      text = "ยกเลิก (คืนสินค้า)";
      style = "bg-dark text-white text-12 px-2 rounded";
      break;
    default:
      text = "";
      style = "";
      break;
  }
  return <span className={style}>{text}</span>;
};
//ชำระเงิน
class ModalPrint extends Component {
  render() {
    return (
      <Modal show={this.props.show} size={"m"} onHide={this.props.onHide}>
        <Modal.Body>
          <div className="w-100 mb-3 text-center">
            <img className="clock-icon my-4" alt="logo" src={CORRECT}></img>
            <h3>
              <b style={{ color: "#595959" }}>{this.props.value.title ? this.props.value.title : "-"}</b>
            </h3>

            <div className="w-100 justify-content-center">
              {this.props.value.status_order_main === 2 ? (
                <div className="general-cash mx-4 mb-2  ">
                  <h4>
                    <b>
                      {this.props.text_modal} {baht(this.props.general)}
                    </b>
                  </h4>
                </div>
              ) : this.props.value.status_order_main === 5 ? (
                <div className="general-cash mx-4 mb-2 ">
                  <h4>
                    <b>
                      {this.props.text_modal} {baht(this.props.general)}
                    </b>
                  </h4>
                </div>
              ) : null}
            </div>

            {this.props.value.action === "main" ? <div className="w-75 text-center mx-auto text-secondary mb-2 text-16">{this.props.value.status_order_main === 2 && (this.props.value.calculate_type === "เงินสด" || this.props.value.calculate_type === "เงินโอน") ? <>คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธรุกรรม</> : <>คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการค้างจ่าย</>}</div> : <div className="w-75 text-center mx-auto text-secondary mb-2 text-16">{this.props.value.detail_print.status_order_main === 1 ? <>คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการค้างจ่าย</> : <>คุณสามารถออกบิลและชำระเงินย้อนหลังได้ที่รายการธรุกรรม</>}</div>}
          </div>

          <div className="w-100 d-flex justify-content-center mb-3">
            {this.props.value.status_order_main !== 5 && (
              <button
                className="btn btn-outline-primary w-50"
                onClick={() => {
                  localStorage.removeItem("bill");
                  let data = this.props.value;
                  if (this.props.vat) {
                    // มีภาษี
                    if (this.props.Screen === "AccruedScreen") {
                      report_3(data, "Transaction");
                    } else {
                      report_3(data, "");
                    }
                  } else {
                    // ไม่มีภาษี
                    if (this.props.Screen === "AccruedScreen") {
                      report_2(data, "sale");
                    } else {
                      report_2(data, "");
                    }
                  }
                }}
              >
                <label className="icon mr-2">{"\uf02f"}</label> {this.props.vat ? "พิมพ์ใบกำกับภาษี" : "พิมพ์ใบส่งสินค้าชั่วคราว"}
              </button>
            )}

            {this.props.Screen === "AccruedScreen" ||
              this.props.value.status_order_main !== 5 ||
              (this.props.value.data_number && this.props.value.data_number.est_number !== "" && (
                <button
                  className="btn btn-outline-primary w-50"
                  onClick={() => {
                    localStorage.removeItem("bill");

                    let data = this.props.value;

                    report_1(data, "");
                  }}
                >
                  <label className="icon mr-2">{"\uf02f"}</label> พิมพ์ใบเสนอราคา
                </button>
              ))}
          </div>
          <div className="w-100 d-flex justify-content-center">
            <button
              className="btn btn-danger w-50"
              type="submit"
              onClick={() => {
                localStorage.removeItem("user_code");
                localStorage.removeItem("bill");

                if (this.props.value.action === "payment") {
                  localStorage.removeItem("bill");
                  window.location.href = "/accrued";
                } else if (this.props.value.action === "paymentlist") {
                  localStorage.removeItem("bill");
                  window.location.href = "/accrued/list-accrued?id=" + this.props.value.member_id + "&type=" + this.props.value.type;
                } else {
                  localStorage.removeItem("bill");

                  localStorage.setItem("status_bill", "ขายสินค้า");
                  window.location.href = "/main";
                }
              }}
            >
              ปิดหน้าต่าง
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
// รายละเอียดการขาย
class ModalDetails extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} size={"lg"}>
        <Modal.Header>
          <Modal.Title>
            <b className="text-18">รายละเอียดการขาย</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* เลขที่เอกสาร */}
          <div className="w-100 d-flex row mx-0 mb-2">
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">เลขที่เอกสาร:</label>
                <label>{this.props.value.ab_number ? this.props.value.ab_number : this.props.value.est_number ? this.props.value.est_number : "-"}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">พนักงานขาย:</label>
                <label>{this.props.value.admin_data ? this.props.value.admin_data.full_name : "-"}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">เลขที่ POS:</label>
                <label>{this.props.value.admin_data ? this.props.value.admin_data.user_code : "-"}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">ลูกค้า:</label>
                <label>{this.props.value.member_data ? this.props.value.member_data.full_name : "-"}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">ประเภทชำระ:</label>
                <label>{this.props.value.payment_type_layer_1 === 0 ? "รอชำระ" : this.props.value.payment_type_layer_1 === 1 ? "เงินสด" : this.props.value.payment_type_layer_1 === 2 ? "เงินเชื่อ" : this.props.value.payment_type_layer_1 === 3 ? "เงินโอน" : this.props.value.payment_type_layer_1 === 4 ? "เป็นงวด" : this.props.value.payment_type_layer_1 === 5 ? "เงินสด / โอน" : "-"}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">วันและเวลา:</label>
                <label>{this.props.value.datetime_create ? format_date(this.props.value.datetime_create) : "-"}</label>
              </div>
            </div>
          </div>

          {this.props.value && this.props.value.payment_type_layer_1 === 3 && (
            <div className="col-12 mb-2 px-1">
              <div className="card bg-secondary-light text-left p-2 border border-secondary">
                <label className="text-14 text-detail-name">
                  <b>รายละเอียดการโอน</b>
                </label>
                <div className="row w-100 justify-content-between px-3">
                  <div className="w-50 text-left">
                    <label className="text-14 text-detail-name">ธนาคาร: {this.props.value.order_list_data[0] && this.props.value.order_list_data[0].payment_detail_data[0].payment_detail_data.bank_name ? this.props.value.order_list_data[0].payment_detail_data[0].payment_detail_data.bank_name : " -"}</label>
                  </div>
                  <div className="w-50 text-left">
                    <label className="text-14 text-left text-detail-name">ชื่อผู้โอน: {this.props.value.order_list_data[0] && this.props.value.order_list_data[0].payment_detail_data[0].payment_detail_data.full_name_transfer ? this.props.value.order_list_data[0].payment_detail_data[0].payment_detail_data.full_name_transfer : " -"}</label>
                  </div>
                </div>
                <div className="row w-100 justify-content-between px-3">
                  <div className="w-50 text-left">
                    <label className="text-14 text-left text-detail-name">วันที่ทำรายการ: {this.props.value.order_list_data[0] && this.props.value.order_list_data[0].payment_detail_data[0].payment_detail_data.datetime_transfer ? this.props.value.order_list_data[0].payment_detail_data[0].payment_detail_data.datetime_transfer : " -"}</label>
                  </div>
                  <div className="w-50 text-left">
                    <label className="text-14 text-left text-detail-name">หมายเลขอ้างอิง: {this.props.value.order_list_data[0] && this.props.value.order_list_data[0].payment_detail_data[0].payment_detail_data.reference_number ? this.props.value.order_list_data[0].payment_detail_data[0].payment_detail_data.reference_number : " -"}</label>
                  </div>
                </div>

                <label className="text-14 text-detail-name">หมายเหตุ: {this.props.value.order_list_data[0] && this.props.value.order_list_data[0].payment_detail_data[0].payment_detail_data.remark_payment_s3 ? this.props.value.order_list_data[0].payment_detail_data[0].payment_detail_data.remark_payment_s3 : " -"}</label>
              </div>
            </div>
          )}
          {this.props.value && this.props.value.payment_type_layer_1 === 2 && (
            <div className="col-12 mb-2 px-1">
              <div className="card bg-secondary-light text-left p-2 border border-secondary">
                <label className="text-14 text-detail-name">หมายเหตุการชำระ: {this.props.value.order_list_data[0] && this.props.value.order_list_data[0].payment_detail_data[0].payment_detail_data.remark_payment_s2 ? this.props.value.order_list_data[0].payment_detail_data[0].payment_detail_data.remark_payment_s2 : "-"}</label>
              </div>
            </div>
          )}

          <div className="w-100 mb-3 overflow-auto" style={{ maxHeight: "50vh" }}>
            <div className="table-responsive rounded-16">
              <table className="table table-sm table-striped table-borderless">
                <thead>
                  <tr className="rounded bg-primary text-white">
                    <th className="text-center">
                      <b>ลำดับ</b>
                    </th>
                    <th className="text-left" style={{ minWidth: 100 }}>
                      <b>ชื่อสินค้า</b>
                    </th>
                    <th className="text-center" style={{ minWidth: 100 }}>
                      <b>รายละเอียด</b>
                    </th>
                    <th className="text-center" style={{ minWidth: 80 }}>
                      <b>ราคา/หน่วย</b>
                    </th>
                    <th className="text-center" style={{ minWidth: 80 }}>
                      <b>จำนวนหลา</b>
                    </th>
                    <th className="text-center" style={{ minWidth: 80 }}>
                      <b>จำนวนไม้</b>
                    </th>
                    <th className="text-right" style={{ minWidth: 80 }}>
                      <b>รวมสุทธิ</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.value.order_list_data &&
                    this.props.value.order_list_data.map((item, index) => (
                      <tr key={index} className={item.product_code === "PD-0000" ? "text-danger" : null}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-left">{item.product_code === "PD-0000" ? item.product_name : item.product_code + " " + item.product_name}</td>
                        <td className="text-center">{item.detail_yard_list.toString().replaceAll(",", ", ")}</td>
                        <td className="text-center">{baht(item.price_by_item)}</td>
                        <td className="text-center">{baht(item.yard_qty.toFixed(2))}</td>
                        <td className="text-center">{number(item.qty)}</td>
                        <td className="text-right">{baht(item.total_price)}</td>
                      </tr>
                    ))}
                  <tr>
                    <td className="text-left" colSpan="4">
                      <b>รวมทั้งหมด</b>
                    </td>
                    <td className="text-center">
                      <b>{baht(Number(this.props.value.total_yard).toFixed(2))} หลา</b>
                    </td>
                    <td className="text-center">
                      <b>{number(Number(this.props.value.total_wood))} ไม้</b>
                    </td>
                    <td className="text-right"></td>
                  </tr>
                  <tr>
                    <td>รายการ</td>
                    <td>{this.props.value.order_list_data && this.props.value.order_list_data.length}</td>
                    <td></td>
                    <td className="text-right" colSpan="3">
                      ภาษีมูลค่าเพิ่ม
                    </td>
                    <td className="text-right">
                      <b>{this.props.value.vat ? baht(this.props.value.vat) : "0.00"}</b>
                    </td>
                  </tr>
                  <tr>
                    <td className="wpx-100">ที่อยู่จัดส่ง</td>
                    <td className="text-left" colSpan="3">
                      {this.props.value.delivery_address ? this.props.value.delivery_address : "-"}
                    </td>
                    <td className="text-right" colSpan="2">
                      รวมค่าสินค้า(รวมภาษี)
                    </td>
                    <td className="text-right">
                      <b> {this.props.value.total_price ? baht(this.props.value.total_price) : "-"}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>หมายเหตุ</td>
                    <td className="text-left" colSpan="3">
                      {this.props.value.remark ? this.props.value.remark : "-"}
                    </td>
                    <td className="text-right" colSpan="2">
                      ค่ามัดจำ
                    </td>
                    <td className="text-right">
                      <b>{this.props.value.deposit ? baht(this.props.value.deposit) : "0.00"}</b>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td colSpan="3"></td>
                    <td className="text-right" colSpan="2">
                      ยอดเงินหลังหักมัดจำ
                    </td>
                    <td className="text-right">
                      <b>{this.props.value.total_price ? baht(Number(this.props.value.total_price - this.props.value.deposit)) : "0.00"}</b>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td colSpan="3"></td>
                    <td className="text-right" colSpan="2">
                      ส่วนลด
                    </td>
                    <td className="text-right text-danger">
                      <b>{this.props.value.discount ? baht(this.props.value.discount) : "0.00"}</b>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td colSpan="3"></td>
                    <td className="text-right" colSpan="2">
                      ค่าบริการจัดส่ง
                    </td>
                    <td className="text-right">
                      <b>{this.props.value.delivery_price ? baht(this.props.value.delivery_price) : "0.00"}</b>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td colSpan="3"></td>
                    <td className="text-right text-danger" colSpan="2">
                      ใช้เงินสะสม
                    </td>
                    <td className="text-right text-danger">
                      <b>{this.props.value.refund_point ? baht(Number(this.props.value.refund_point)) : "0.00"}</b>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td colSpan="3"></td>
                    <td className="text-right" colSpan="2">
                      ยอดรวม
                    </td>
                    <td className="text-right">
                      <b>{this.props.value.total_price_final ? baht(Number(this.props.value.total_price_final)) : "0.00"}</b>
                    </td>
                  </tr>
                </tbody>

                {this.props.value.status_order_main === 3 && (
                  <tfoot style={{ backgroundColor: "#CDCDCD" }}>
                    <td className="text-left text-danger">
                      <b>สถานะ: </b> ยกเลิก
                    </td>
                    <td className="text-right text-danger">
                      <b>หมายเหตุการยกเลิก:</b>
                    </td>
                    <td className="text-left text-danger" colSpan="5">
                      {this.props.value.remark_cancel ? this.props.value.remark_cancel : "-"}
                    </td>
                  </tfoot>
                )}
              </table>
            </div>
          </div>
          <div className="w-100 text-center">
            <button className="btn btn-outline-primary px-3 mx-auto" onClick={this.props.onHide}>
              ปิดหน้าต่าง
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
// ประวัติการชำระเงินการขาย
class ModalSaleHistory extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} size={"lg"}>
        <Modal.Header>
          <Modal.Title>
            <b className="text-18">ประวัติการชำระเงิน</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mx-0">
            <div className="col-6  col-sm-6 col-md-4 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center">
                <label className="text-14">วันที่สร้าง:</label>
                <label>{this.props.history && date_invoice(this.props.history.datetime_create)}</label>
              </div>
            </div>
            <div className="col-6  col-sm-6 col-md-4 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center">
                <label className="text-14">เลขที่เอกสาร:</label>
                <label>{(this.props.history && this.props.history.ab_number) || (this.props.history && this.props.history.est_number)}</label>
              </div>
            </div>
            <div className="col-6  col-sm-6 col-md-4 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center">
                <label className="text-14">ชื่อ-นามสกุล ลูกค้า:</label>
                <label>{this.props.history && this.props.history.member_name}</label>
              </div>
            </div>
            <div className="col-6  col-sm-6 col-md-4 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center">
                <label className="text-14">กำหนดวันชำระ:</label>
                <label>{this.props.history && date_invoice(this.props.history.due_date)}</label>
              </div>
            </div>
            <div className="col-6  col-sm-6 col-md-4 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center">
                <label className="text-14">ยอดเงินทั้งหมด:</label>
                <label>{this.props.history && "฿" + baht(this.props.history.total_price_final)}</label>
              </div>
            </div>
            <div className="col-6  col-sm-6 col-md-4 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center">
                <label className="text-14">ยอดเงินค้างชำระ:</label>
                <label>{this.props.history && "฿" + baht(this.props.history.total_balance)}</label>
              </div>
            </div>
          </div>

          <div className="w-100 mb-3 mt-2 overflow-auto" style={{ maxHeight: "50vh" }}>
            <div className="table-responsive rounded-16">
              <table className="table table-sm table-striped table-borderless">
                <thead>
                  <tr className="rounded bg-primary text-white">
                    <th className="text-left">เลขที่ Invoice</th>
                    <th className="text-left" style={{ minWidth: 80 }}>
                      วันที่ชำระ
                    </th>
                    <th className="text-right">ยอดรวม</th>
                    <th className="text-right" style={{ minWidth: 90 }}>
                      ยอดค้างชำระ
                    </th>
                    <th className="text-right" style={{ minWidth: 120 }}>
                      ยอดเงินที่ชำระแล้ว
                    </th>
                    <th className="text-right" style={{ minWidth: 120 }}>
                      สถานะการชำระเงิน
                    </th>
                    <th className="text-center" style={{ minWidth: 90 }}>
                      ธนาคาร
                    </th>
                    <th className="text-right">ส่วนลด</th>
                    <th className="text-right" style={{ minWidth: 120 }}>
                      จำนวนเงินที่โอน
                    </th>
                    <th className="text-right" style={{ minWidth: 120 }}>
                      จำนวนเงินชำระสด
                    </th>
                    <th className="text-right" style={{ minWidth: 120 }}>
                      จำนวนเงินคืนสะสม
                    </th>
                    <th className="text-left" style={{ minWidth: 120 }}>
                      วันที่ในระบบ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.history && this.props.history.data_list.length === 0 && (
                    <tr>
                      <td colSpan={10} className="text-center table-light">
                        -- ไม่มีข้อมูลการชำระเงิน --
                      </td>
                    </tr>
                  )}

                  {this.props.history &&
                    this.props.history.data_list.map((item, index) => (
                      <tr key={index}>
                        <td className="text-left">{item.invoice_number}</td>
                        <td className="text-left">{format_duedate_sale(item.date_pay)}</td>
                        <td className="text-right">฿{baht(item.total_price)}</td>
                        <td className="text-right">฿{baht(item.total_balance)}</td>
                        <td className="text-right">฿{baht(item.total_pay)}</td>
                        <td className="text-right">{item.payment_type === 0 ? "รอดำเนินการ" : item.payment_type === 1 ? "เงินสด" : item.payment_type === 2 ? "เงินเชื่อ" : item.payment_type === 3 ? "เงินโอน" : item.payment_type === 4 ? "แบ่งงวด" : item.payment_type === 5 ? "เงินสด / โอน" : "-"}</td>
                        <td className="text-center">{item.bank_name ? item.bank_name : "-"}</td>
                        <td className="text-right">฿{item.discount ? baht(item.discount) : "0.00"}</td>
                        <td className="text-right">฿{item.total_pay < item.transfer_amount ? baht(item.total_pay) : baht(item.transfer_amount)}</td>
                        <td className="text-right">฿{item.total_pay < item.cash_amount ? baht(item.total_pay) : baht(item.cash_amount)}</td>
                        <td className="text-right">฿{item.refund_point ? baht(item.refund_point) : baht(0)}</td>

                        <td className="text-left">{format_date(item.datetime_create, "dd mmm yy").split(",")[0]}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-center">
            <button className="btn btn-outline-primary px-3 mx-auto" onClick={this.props.onHide}>
              ปิดหน้าต่าง
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
// รายการผ่อนชำระ
class ModalInstallment extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} size={"lg"}>
        <Modal.Header>
          <Modal.Title>
            <b className="text-18">รายการผ่อนชำระ</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* เลขที่เอกสาร */}
          <div className="w-100 d-flex row mx-0 mb-3">
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">เลขที่เอกสาร:</label>
                <label>{this.props.value.ab_number ? this.props.value.ab_number : this.props.value.est_number ? this.props.value.est_number : "-"}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">พนักงานขาย:</label>
                <label>{this.props.value.admin_data ? this.props.value.admin_data.full_name : "-"}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">เลขที่ POS:</label>
                <label>{this.props.value.admin_data ? this.props.value.admin_data.user_code : "-"}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">ชำระทั้งหมด:</label>
                <label>{this.props.installment_detail && this.props.installment_detail.length} งวด</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">ยอดเงินทั้งหมด:</label>
                <label>฿ {this.props.value.total_price_final ? baht(Number(this.props.value.total_price_final)) : "0.00"}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">ยอดเงินค้างชำระ:</label>
                <label>฿ {baht(Number(this.props.value.total_balance))}</label>
              </div>
            </div>
          </div>
          <div className="w-100 mb-3 overflow-auto" style={{ maxHeight: "50vh" }}>
            <div className="table-responsive rounded-16  ">
              <table className="table table-sm table-striped table-borderless">
                <thead>
                  <tr className="rounded bg-primary text-white">
                    <th className="text-left">จำนวนงวด</th>
                    <th className="text-left ">ยอดเงิน / งวด</th>
                    <th className="text-right">ยอดเงินชำระ / งวด</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.installment_detail &&
                    this.props.installment_detail.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1 + "/" + this.props.installment_detail.length}</td>
                        <td>{baht(Number(item.total_pay))}</td>
                        <td className="text-right">{baht(Number(item.pay))}</td>
                      </tr>
                    ))}
                  <tr>
                    <td></td>
                    <td>
                      <b>ยอดเงินทั้งหมด</b>
                    </td>
                    <td className="text-right">
                      <b>{baht(Number(this.props.i_all_total_pay)) + "บาท"}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-100 text-center">
            <button className="btn btn-outline-primary px-3 mx-auto" onClick={this.props.onHide}>
              ปิดหน้าต่าง
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
// รายละเอียดใบรับสินค้า
class ModalDetailsReceipt extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} size={"lg"}>
        <Modal.Header>
          <Modal.Title>
            <b className="text-18">รายละเอียดใบรับสินค้า</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* เลขที่เอกสาร */}
          <div className="w-100 d-flex row mx-0 mb-3">
            <div className="col-6 col-sm-6 col-lg-3 col-xl-3 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">วันที่รับ:</label>
                <label>{this.props.value.receive_date ? format_date_notime(this.props.value.receive_date) : "-"}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-lg-3 col-xl-3 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">ผู้จำหน่าย:</label>
                <label>{this.props.value.company_supplier_data ? this.props.value.company_supplier_data.company_name : "-"}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-lg-3 col-xl-3 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">เอกสารอ้างอิง</label>
                <label>{this.props.value.ref_receipt_code ? this.props.value.ref_receipt_code : "-"}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-lg-3 col-xl-3 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">โกดัง</label>
                <label>{this.props.value.goods_receipt_list ? this.props.value.goods_receipt_list[0].all_stock_data.warehouse_name : "-"}</label>
              </div>
            </div>
          </div>

          <div className="w-100 mb-3 overflow-auto" style={{ maxHeight: "50vh" }}>
            <div className="table-responsive rounded-16">
              <table className="table table-sm table-striped table-borderless">
                <thead>
                  <tr className="rounded bg-primary text-white">
                    <th className="text-center">
                      <b>ลำดับ</b>
                    </th>
                    <th className="text-center" style={{ minWidth: 80 }}>
                      <b>รหัสสินค้า</b>
                    </th>
                    <th className="text-left" style={{ minWidth: 120 }}>
                      <b>ชื่อสินค้า</b>
                    </th>

                    <th className="text-center">
                      <b>จำนวน(ไม้)</b>
                    </th>
                    <th className="text-right" style={{ minWidth: 80 }}>
                      <b>จำนวนหลา</b>
                    </th>
                    {this.props.user_type === "owner" ? (
                      <th className="text-right" style={{ minWidth: 80 }}>
                        <b>ราคาที่รับมา</b>
                      </th>
                    ) : null}
                    {this.props.user_type === "owner" ? (
                      <th className="text-right" style={{ minWidth: 80 }}>
                        <b>ยอดรวมสุทธิ</b>
                      </th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {this.props.value &&
                    this.props.value.goods_receipt_list.map((item, index) => (
                      <tr key={index}>
                        <td className="text-center">{Number(index + 1)}</td>
                        <td className="text-center">{item.all_stock_data.product_main_data.product_code}</td>
                        <td className="text-left">{item.all_stock_data.product_main_data.product_name}</td>
                        <td className="text-center">{number(item.qty)}</td>
                        <td className="text-right">{baht(item.yard_qty)}</td>
                        {this.props.user_type === "owner" ? <td className="text-right">{baht(item.price_by_item)}</td> : null}
                        {this.props.user_type === "owner" ? <td className="text-right">{baht(item.total_price_by_item)}</td> : null}
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    {/* <td></td>
                                        <td></td> */}
                    <td className="text-left wpx-100">
                      <b>ยอดรวม</b>
                    </td>
                    <td className="text-center">
                      <b>{this.props.value && this.props.value.goods_receipt_list ? number(total(this.props.value.goods_receipt_list, "qty")) : "0"} ไม้</b>
                    </td>
                    <td className="text-right">
                      <b>{this.props.value && this.props.value.goods_receipt_list ? baht(total(this.props.value.goods_receipt_list, "yard_qty")) : "0.00"} หลา</b>
                    </td>
                    {this.props.user_type === "owner" ? <td></td> : null}
                    {this.props.user_type === "owner" ? (
                      <td className="text-right wpx-170">
                        <b>{this.props.value && this.props.value.goods_receipt_list ? baht(total(this.props.value.goods_receipt_list, "total_price_by_item")) : "0.00"} บาท</b>
                      </td>
                    ) : null}
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div className="w-100 text-center">
            <button className="btn btn-outline-primary px-3 mx-auto" onClick={this.props.onHide}>
              ปิดหน้าต่าง
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
// รายละเอียดใบรับสินค้า
class ModalEditReceipt extends Component {
  render() {
    return (
      <Modal show={this.props.show} size={"lg"}>
        <Modal.Header>
          <Modal.Title>
            <b className="text-18">แก้ไขราคาที่รับมา</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* เลขที่เอกสาร */}
          <div className="w-100 d-flex row mx-0 mb-3">
            <div className="col-6 col-sm-6 col-lg-3 col-xl-3 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">วันที่รับ:</label>
                <label>{this.props.value.receive_date ? format_date_notime(this.props.value.receive_date) : "-"}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-lg-3 col-xl-3 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">ผู้จำหน่าย:</label>
                <label>{this.props.value.company_supplier_data ? this.props.value.company_supplier_data.company_name : "-"}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-lg-3 col-xl-3 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">เอกสารอ้างอิง</label>
                <label>{this.props.value.ref_receipt_code ? this.props.value.ref_receipt_code : "-"}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-lg-3 col-xl-3 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">โกดัง</label>
                <label>{this.props.value.goods_receipt_list ? this.props.value.goods_receipt_list[0].all_stock_data.warehouse_name : "-"}</label>
              </div>
            </div>
          </div>

          <div className="w-100 mb-3 overflow-auto" style={{ maxHeight: "50vh" }}>
            <div className="table-responsive rounded-16">
              <table className="table table-sm table-striped table-borderless">
                <thead>
                  <tr className="rounded bg-primary text-white">
                    <th className="text-center">
                      <b>ลำดับ</b>
                    </th>
                    <th className="text-center" style={{ minWidth: 80 }}>
                      <b>รหัสสินค้า</b>
                    </th>
                    <th className="text-left" style={{ minWidth: 120 }}>
                      <b>ชื่อสินค้า</b>
                    </th>
                    <th className="text-center">
                      <b>จำนวน(ไม้)</b>
                    </th>
                    <th className="text-right" style={{ minWidth: 80 }}>
                      <b>จำนวนหลา</b>
                    </th>
                    <th className="text-right" style={{ minWidth: 80 }}>
                      <b>ราคาที่รับมา</b>
                    </th>
                    <th className="text-right" style={{ minWidth: 80 }}>
                      <b>ยอดรวมสุทธิ</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.value &&
                    this.props.value.goods_receipt_list.map((item, index) => (
                      <tr key={index}>
                        <td className="text-center">{Number(index + 1)}</td>
                        <td className="text-center">{item.all_stock_data.product_main_data.product_code}</td>
                        <td className="text-left">{item.all_stock_data.product_main_data.product_name}</td>
                        <td className="text-center">{number(item.qty)}</td>
                        <td className="text-right">{baht(item.yard_qty)}</td>
                        <td className="text-right">{baht(item.price_by_item)}</td>
                        <td className="text-right">{baht(item.total_price_by_item)}</td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    {/* <td></td>
                                        <td></td> */}
                    <td className="text-left wpx-100">
                      <b>ยอดรวม</b>
                    </td>
                    <td className="text-center">
                      <b>{this.props.value && this.props.value.goods_receipt_list ? number(total(this.props.value.goods_receipt_list, "qty")) : "0"} ไม้</b>
                    </td>
                    <td className="text-right">
                      <b>{this.props.value && this.props.value.goods_receipt_list ? baht(total(this.props.value.goods_receipt_list, "yard_qty")) : "0.00"} หลา</b>
                    </td>
                    <td></td>
                    <td className="text-right wpx-170">
                      <b>{this.props.value && this.props.value.goods_receipt_list ? baht(total(this.props.value.goods_receipt_list, "total_price_by_item")) : "0.00"} บาท</b>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div className="w-100 text-center">
            <button className="btn btn-outline-primary px-3 mx-auto" onClick={this.props.onClick}>
              บันทึก
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
// รายละเอียดค่าใช้จ่าย
class ModalDetailsExpenses extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} size={"lg"}>
        <Modal.Header>
          <Modal.Title>
            <b className="text-18">รายละเอียดค่าใช้จ่าย</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* header */}
          <div className="card-header row d-flex mx-0 justify-content-around align-items-center bg-secondary rounded-top-16">
            <label>วันที่: {this.props.value && format_date(this.props.value.data.datetime_create)}</label>
            <div className="d-none d-xl-block" style={{ height: 40, width: 1, backgroundColor: "#c9c9c9" }}></div>
            <div className="col-12 d-lg-none">
              <hr />
            </div>
            <label>เลขที่เอกสาร: {this.props.value && this.props.value.data.expense_main_number}</label>
            <div className="d-none d-xl-block" style={{ height: 40, width: 1, backgroundColor: "#c9c9c9" }}></div>
            <div className="col-12  d-lg-none">
              <hr />
            </div>
            <label className="mb-0">เอกสารอ้างอิง: {this.props.value && this.props.value.data.ref_receipt_code ? this.props.value.data.ref_receipt_code : "-"}</label>
          </div>

          {/* table */}
          <div className="w-100 mb-3 overflow-auto mx-0" style={{ maxHeight: "50vh" }}>
            <div className="table-responsive">
              <table className="table table-striped table-borderless">
                <thead>
                  <tr className="bg-secondary">
                    <th className="text-center">
                      <b>ลำดับ</b>
                    </th>
                    <th className="text-left">
                      <b>รายละเอียด</b>
                    </th>
                    <th className="text-left">
                      <b>หมวดหมู่</b>
                    </th>
                    <th className="text-right">
                      <b>จำนวน/หน่วย</b>
                    </th>
                    <th className="text-right">
                      <b>ราคา/หน่วย</b>
                    </th>
                    <th className="text-right">
                      <b>ยอดรวมสุทธิ</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.value &&
                    this.props.value.data.expense_list.map((item, index) => (
                      <tr key={index}>
                        <td className="text-center">{Number(index + 1)}</td>
                        <td className="text-left">{item.detail}</td>
                        <td className="text-left">{item.expense_category_name}</td>
                        <td className="text-right">{baht(item.unit)}</td>
                        <td className="text-right">{baht(item.price_by_unit)}</td>
                        <td className="text-right">{baht(item.total_price)}</td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr className="bg-secondary">
                    <th className="text-right" colSpan={5}>
                      <b>รวม</b>
                    </th>
                    <th className="text-right">
                      <b>฿{this.props.value && baht(total(this.props.value.data.expense_list, "total_price"))}</b>
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>

          <div className="w-100 text-center">
            <button className="btn btn-outline-primary px-3 mx-auto" onClick={this.props.onHide}>
              ปิดหน้าต่าง
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
// รายละเอียดการย้ายสต๊อก
class ModalDetailsMoveStock extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} size={"lg"}>
        <Modal.Header>
          <Modal.Title>
            <b className="text-18">รายละเอียดการย้ายสต๊อก</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* เลขที่เอกสาร */}
          <div className="w-100 d-flex row mx-0 mb-3">
            <div className="col-6 col-sm-6 col-lg-3 col-xl-3 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">เลขที่เอกสาร:</label>
                <label>{this.props.value.document_code ? this.props.value.document_code : "-"}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-lg-3 col-xl-3 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">วันที่:</label>
                <label>{this.props.value.datetime_create ? format_date(this.props.value.datetime_create) : "-"}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-lg-3 col-xl-3 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">จากสถานที่:</label>
                <label>{this.props.value.warehouse_from_name ? this.props.value.warehouse_from_name : "-"}</label>
              </div>
            </div>

            <div className="col-6 col-sm-6  col-lg-3 col-xl-3 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">ไปยังสถานที่:</label>
                <label>{this.props.value.warehouse_to_name ? this.props.value.warehouse_to_name : "-"}</label>
              </div>
            </div>
          </div>

          <div className="w-100 mb-3">
            <div className="table-responsive rounded-16">
              <table className="table table-sm table-striped table-borderless">
                <thead>
                  <tr className="rounded bg-primary text-white">
                    <th className="text-left" style={{ minWidth: 80 }}>
                      <b>รหัสสินค้า</b>
                    </th>
                    <th className="text-left" style={{ minWidth: 100 }}>
                      <b>ชื่อสินค้า</b>
                    </th>
                    <th className="text-left">
                      <b>สี</b>
                    </th>
                    <th className="text-left">
                      <b>ดีไซน์</b>
                    </th>
                    <th className="text-center">
                      <b>จำนวน(ไม้)</b>
                    </th>
                    <th className="text-right">
                      <b>จำนวน(หลา)</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.value &&
                    this.props.value.data_list.map((item, index) => (
                      <tr key={index}>
                        <td className="text-left">{item.product_code}</td>
                        <td className="text-left">{item.product_name}</td>
                        <td className="text-left">{item.product_color}</td>
                        <td className="text-left">{item.product_design}</td>
                        <td className="text-center">{number(item.qty)}</td>
                        <td className="text-right">{baht(item.yard_qty)}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-100 text-center">
            <button className="btn btn-outline-primary px-3 mx-auto" onClick={this.props.onHide}>
              ปิดหน้าต่าง
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
// รายละเอียดใบแจ้งหนี้
class ModalInvoice extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} size={"lg"}>
        <Modal.Header>
          <Modal.Title>
            <b className="text-18">รายละเอียดใบแจ้งหนี้</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mx-0">
            <div className="col-6 col-md-6 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center">
                <label className="text-14">วันที่สร้าง:</label>
                <label>{this.props.value && date_invoice(this.props.value.datetime_create)}</label>
              </div>
            </div>
            <div className="col-6 col-md-6 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center">
                <label className="text-14">เลขที่เอกสาร:</label>
                <label>{this.props.value && this.props.value.invoice_number}</label>
              </div>
            </div>
            <div className="col-6 col-md-6 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center">
                <label className="text-14">กำหนดวันชำระ:</label>
                <label>{this.props.value && date_invoice(this.props.value.due_date)}</label>
              </div>
            </div>
            <div className="col-6 col-md-6 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center">
                <label className="text-14">ชื่อ-นามสกุล ลูกค้า:</label>
                <label>{this.props.value && this.props.value.cutomer_name}</label>
              </div>
            </div>
          </div>

          <div className="w-100 mb-3">
            <div className="table-responsive rounded-16">
              <table className="table table-sm table-striped table-borderless">
                <thead>
                  <tr className="rounded bg-primary text-white">
                    <th className="text-left">
                      <b>เลขที่เอกสาร</b>
                    </th>
                    <th className="text-left">
                      <b>วันที่เปิด Invoice</b>
                    </th>
                    <th className="text-right">
                      <b>ยอดค้างชำระ</b>
                    </th>
                    <th className="text-right">
                      <b>ยอดรวมทั้งสิ้น</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.value && this.props.value.order_main_list.length === 0 && (
                    <tr>
                      <td colSpan={4} className="text-center table-light">
                        -- ไม่มีข้อมูล --
                      </td>
                    </tr>
                  )}

                  {this.props.value &&
                    this.props.value.order_main_list.map((item, index) => (
                      <tr key={index}>
                        <td className="text-left">{item.order_main_number}</td>
                        <td className="text-left">{date_invoice(item.datetime_create)}</td>
                        <td className="text-right">฿{baht(item.total_balance)}</td>
                        <td className="text-right">฿{baht(item.total_price)}</td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    <td className="text-right">
                      <b>ยอดรวมสุทธิ</b>
                    </td>
                    <td className="text-right">
                      <b>{this.props.value.order_main_list ? baht(total(this.props.value.order_main_list, "total_price")) : "0.00"}</b>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-center">
            <button className="btn btn-outline-primary px-3 mx-auto" onClick={this.props.onHide}>
              ปิดหน้าต่าง
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
// ประวัติการชำระเงิน
class ModalInvoiceHistory extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} size={"lg"}>
        <Modal.Header>
          <Modal.Title>
            <b className="text-18">ประวัติการชำระเงิน</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mx-0">
            <div className="col-6  col-sm-6 col-md-4 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center">
                <label className="text-14">วันที่สร้าง:</label>
                <label>{this.props.history && date_invoice(this.props.history.datetime_create)}</label>
              </div>
            </div>
            <div className="col-6  col-sm-6 col-md-4 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center">
                <label className="text-14">เลขที่ใบแจ้งหนี้:</label>
                <label>{this.props.history && this.props.history.invoice_number}</label>
              </div>
            </div>
            <div className="col-6  col-sm-6 col-md-4 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center">
                <label className="text-14">ชื่อ-นามสกุล ลูกค้า:</label>
                <label>{this.props.history && this.props.history.member_name}</label>
              </div>
            </div>
            <div className="col-6  col-sm-6 col-md-4 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center">
                <label className="text-14">กำหนดวันชำระ:</label>
                <label>{this.props.history && date_invoice(this.props.history.due_date)}</label>
              </div>
            </div>
            <div className="col-6  col-sm-6 col-md-4 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center">
                <label className="text-14">ยอดเงินทั้งหมด:</label>
                <label>{this.props.history && "฿" + baht(this.props.history.total_price)}</label>
              </div>
            </div>
            <div className="col-6  col-sm-6 col-md-4 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center">
                <label className="text-14">ยอดเงินค้างชำระ:</label>
                <label>{this.props.history && "฿" + baht(this.props.history.total_balance)}</label>
              </div>
            </div>
          </div>

          <div className="w-100 mb-3 mt-2 overflow-auto" style={{ maxHeight: "50vh" }}>
            <div className="table-responsive rounded-16">
              <table className="table table-sm table-striped table-borderless">
                <thead>
                  <tr className="rounded bg-primary text-white">
                    <th className="text-left">
                      <b>เลขที่ Invoice</b>
                    </th>
                    <th className="text-left" style={{ minWidth: 80 }}>
                      <b>วันที่ชำระ</b>
                    </th>
                    <th className="text-right">
                      <b>ยอดรวม</b>
                    </th>
                    <th className="text-right">
                      <b>ยอดค้างชำระ</b>
                    </th>
                    <th className="text-right">
                      <b>ยอดเงินที่ชำระแล้ว</b>
                    </th>
                    <th className="text-right" style={{ minWidth: 80 }}>
                      <b>สถานะการชำระเงิน</b>
                    </th>
                    <th className="text-center" style={{ minWidth: 80 }}>
                      <b>ธนาคาร</b>
                    </th>
                    <th className="text-right">
                      <b>ส่วนลด</b>
                    </th>
                    <th className="text-right">
                      <b>จำนวนเงินที่โอน</b>
                    </th>
                    <th className="text-right">
                      <b>จำนวนเงินชำระสด</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.history && this.props.history.data_list.length === 0 && (
                    <tr>
                      <td colSpan={10} className="text-center table-light">
                        -- ไม่มีข้อมูลการชำระเงิน --
                      </td>
                    </tr>
                  )}

                  {this.props.history &&
                    this.props.history.data_list.map((item, index) => (
                      <tr key={index}>
                        <td className="text-left">{item.invoice_number}</td>
                        <td className="text-left">{format_duedate_sale(item.date_pay)}</td>
                        <td className="text-right">{baht(item.total_price)}</td>
                        <td className="text-right">{baht(item.total_balance)}</td>
                        <td className="text-right">{baht(item.total_pay)}</td>
                        <td className="text-right">{item.payment_type === 1 ? "เงินสด" : item.payment_type === 2 ? "เงินเชื่อ" : item.payment_type === 3 ? "เงินโอน" : item.payment_type === 4 ? "แบ่งงวด" : item.payment_type === 5 ? "เงินสด / โอน" : "-"}</td>
                        <td className="text-center">{item.bank_name ? item.bank_name : "-"}</td>
                        <td className="text-right">{item.discount ? baht(item.discount) : "0.00"}</td>
                        <td className="text-right">{baht(item.transfer_amount)}</td>
                        <td className="text-right">{baht(item.cash_amount)}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-center">
            <button className="btn btn-outline-primary px-3 mx-auto" onClick={this.props.onHide}>
              ปิดหน้าต่าง
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
//รายละเอียดสถานะส่งของ
class ModalDetailStatusDelivery extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} size={"lg"}>
        <Modal.Header>
          <Modal.Title>
            <b className="text-18">รายละเอียดสถานะส่งของ</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mx-0 text-14">
            <div className="col-6  col-sm-6 col-md-3 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center hpx-100">
                <label className="text-14">เลขที่เอกสาร:</label>
                <label>{this.props.value && this.props.value.invoice_number}</label>
              </div>
            </div>

            <div className="col-6  col-sm-6 col-md-3 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center hpx-100">
                <label className="text-14">พนักงานส่ง:</label>
                {this.props.value && this.props.value.relation_status_delivery.user_member_list && this.props.value.relation_status_delivery.user_member_list.length === 1 ? (
                  <label>1. {this.props.value && this.props.value.relation_status_delivery.user_member_list[0]}</label>
                ) : this.props.value.relation_status_delivery === "" ? (
                  "-"
                ) : (
                  <>
                    <label>1. {this.props.value && this.props.value.relation_status_delivery.user_member_list[0]}</label>
                    <label>2. {this.props.value && this.props.value.relation_status_delivery.user_member_list[1]}</label>
                  </>
                )}
              </div>
            </div>
            <div className="col-6  col-sm-6 col-md-3 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center hpx-100">
                <label className="text-14">ยอดเงินชำระ:</label>
                <label>{this.props.value && "฿" + baht(this.props.value.total_price_final)}</label>
              </div>
            </div>

            <div className="col-6  col-sm-6 col-md-3 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center hpx-100">
                <label className="text-14">สถานะ:</label>
                <label>{this.props.value && this.props.value.status_delivery === 1 ? "ยังไม่ได้ส่ง" : this.props.value.status_delivery === 2 ? "อยู่ระหว่างการขนส่ง" : this.props.value.status_delivery === 3 ? "ส่งแล้ว(ยังไม่ได้รับเงิน)" : this.props.value.status_delivery === 4 ? "ส่งแล้ว" : "-"}</label>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12 mb-2 px-1">
              <div className="card  bg-secondary-light p-3 align-items-left">
                <div className="row d-flex justify-content-between px-3">
                  <label className="text-left">วันที่อัปเดต:</label>
                  <label>{this.props.value && this.props.value.datetime_update_delivery ? format_date(this.props.value.datetime_update_delivery) : "-"}</label>
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 mb-3 mt-3">
            <div className="table-responsive rounded-16 ">
              <table className="table table-sm table-striped table-borderless ">
                <tbody>
                  <tr>
                    <td className="text-left">วิธีรับเงิน</td>
                    <td className="text-right">{this.props.value.status_delivery_payment_type === 0 ? "" : this.props.value.status_delivery_payment_type === 1 ? "เงินสด" : this.props.value.status_delivery_payment_type === 2 ? "เงินโอน" : this.props.value.status_delivery_payment_type === 3 ? "โอน/เงินสด" : this.props.value.status_delivery_payment_type === 4 ? "เครติด" : "-"}</td>
                  </tr>

                  {this.props.value.status_delivery_payment_type !== 4 && (
                    <>
                      <tr>
                        <td className="text-left">จำนวนเงินสด:</td>
                        <td className="text-right">{this.props.value && this.props.value.status_delivery_payment_type === 1 ? baht(this.props.value.total_price_final) : this.props.value && this.props.value.status_delivery_payment_type === 3 ? baht(this.props.value.status_delivery_cash_amount) : "0.00"}</td>
                      </tr>
                      <tr>
                        <td className="text-left">จำนวนเงินโอน:</td>
                        <td className="text-right">{this.props.value && this.props.value.status_delivery_payment_type === 3 ? baht(this.props.value.status_delivery_transfer_amount) : this.props.value && this.props.value.status_delivery_payment_type === 2 ? baht(this.props.value.total_price_final) : "0.00"}</td>
                      </tr>
                    </>
                  )}

                  {this.props.value.status_delivery_payment_type === 4 && (
                    <>
                      <tr>
                        <td className="text-left">ผู้อนุมัติเครดิต:</td>
                        <td className="text-right">{this.props.value && this.props.value.status_delivery_credit_approver_by ? this.props.value.status_delivery_credit_approver_by : "-"}</td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <td className="text-left">ผู้ส่งของ:</td>
                    <td className="text-right">{this.props.value && this.props.value.status_delivery_received_by ? this.props.value.status_delivery_received_by : "-"}</td>
                  </tr>

                  {this.props.value.status_delivery === 1 && (
                    <>
                      <tr>
                        <td className="text-left">
                          <b>หมายเหตุ:</b>
                        </td>
                        <td className="text-right">{this.props.value && this.props.value.status_delivery_remark_status1 ? this.props.value.status_delivery_remark_status1 : "-"}</td>
                      </tr>
                    </>
                  )}

                  {this.props.value.status_delivery === 3 && (
                    <>
                      <tr>
                        <td className="text-left">
                          <b>หมายเหตุ:</b>
                        </td>
                        <td className="text-right">{this.props.value && this.props.value.status_delivery_remark_status3 ? this.props.value.status_delivery_remark_status3 : "-"}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-center">
            <button className="btn btn-outline-primary px-3 mx-auto" onClick={this.props.onHide}>
              ปิดหน้าต่าง
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
//ประวัติรายละเอียดสถานะส่งของ
class ModalDetailStatusDeliveryHistory extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} size={"lg"}>
        <Modal.Header>
          <Modal.Title>
            <b className="text-18">รายละเอียดสถานะส่งของ</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mx-0 text-14">
            <div className="col-6  col-sm-6 col-md-3 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center hpx-100">
                <label className="text-14">เลขที่เอกสาร:</label>
                <label>{this.props.value && this.props.value.invoice_number}</label>
              </div>
            </div>

            <div className="col-6  col-sm-6 col-md-3 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center hpx-100">
                <label className="text-14">พนักงานส่ง:</label>
                {this.props.value && this.props.value.relation_status_delivery[0].user_member_list && this.props.value.relation_status_delivery[0].user_member_list.length === 1 ? (
                  <label>1. {this.props.value && this.props.value.relation_status_delivery[0].user_member_list[0]}</label>
                ) : this.props.value.relation_status_delivery === "" ? (
                  "-"
                ) : (
                  <>
                    <label>1. {this.props.value && this.props.value.relation_status_delivery[0].user_member_list[0]}</label>
                    <label>2. {this.props.value && this.props.value.relation_status_delivery[0].user_member_list[1]}</label>
                  </>
                )}
              </div>
            </div>
            <div className="col-6  col-sm-6 col-md-3 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center hpx-100">
                <label className="text-14">ยอดเงินชำระ:</label>
                <label>{this.props.value && "฿" + baht(this.props.value.total_price_final)}</label>
              </div>
            </div>

            <div className="col-6  col-sm-6 col-md-3 mb-2 px-1">
              <div className="card bg-secondary-light p-2 align-items-center hpx-100">
                <label className="text-14">สถานะ:</label>
                <label>{this.props.value && this.props.value.status_delivery === 1 ? "ยังไม่ได้ส่ง" : this.props.value.status_delivery === 2 ? "อยู่ระหว่างการขนส่ง" : this.props.value.status_delivery === 3 ? "ส่งแล้ว(ยังไม่ได้รับเงิน)" : this.props.value.status_delivery === 4 ? "ส่งแล้ว" : "-"}</label>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12 mb-2 px-1">
              <div className="card  bg-secondary-light p-3 align-items-left">
                <div className="row d-flex justify-content-between px-3">
                  <label className="text-left">วันที่อัปเดต:</label>
                  <label>{this.props.value && this.props.value.datetime_update_delivery ? format_date(this.props.value.datetime_update_delivery) : "-"}</label>
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 mb-3 mt-3">
            <div className="table-responsive rounded-16 ">
              <table className="table table-sm table-striped table-borderless ">
                <tbody>
                  <tr>
                    <td className="text-left">วิธีรับเงิน</td>
                    <td className="text-right">{this.props.value.status_delivery_payment_type === 0 ? "-" : this.props.value.status_delivery_payment_type === 1 ? "เงินสด" : this.props.value.status_delivery_payment_type === 2 ? "เงินโอน" : this.props.value.status_delivery_payment_type === 3 ? "โอน/เงินสด" : this.props.value.status_delivery_payment_type === 4 ? "เครติด" : "-"}</td>
                  </tr>

                  {this.props.value.status_delivery_payment_type !== 4 && (
                    <>
                      <tr>
                        <td className="text-left">จำนวนเงินสด:</td>
                        <td className="text-right">{this.props.value && this.props.value.status_delivery_payment_type === 1 ? baht(this.props.value.total_price_final) : this.props.value && this.props.value.status_delivery_payment_type === 3 ? baht(this.props.value.status_delivery_cash_amount) : "0.00"}</td>
                      </tr>
                      <tr>
                        <td className="text-left">จำนวนเงินโอน:</td>
                        <td className="text-right">{this.props.value && this.props.value.status_delivery_payment_type === 3 ? baht(this.props.value.status_delivery_transfer_amount) : this.props.value && this.props.value.status_delivery_payment_type === 2 ? baht(this.props.value.total_price_final) : "0.00"}</td>
                      </tr>
                    </>
                  )}

                  {this.props.value.status_delivery_payment_type === 4 && (
                    <>
                      <tr>
                        <td className="text-left">ผู้อนุมัติเครดิต:</td>
                        <td className="text-right">{this.props.value && this.props.value.status_delivery_credit_approver_by ? this.props.value.status_delivery_credit_approver_by : "-"}</td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <td className="text-left">ผู้ส่งของ:</td>
                    <td className="text-right">{this.props.value && this.props.value.status_delivery_received_by ? this.props.value.status_delivery_received_by : "-"}</td>
                  </tr>

                  {this.props.value.status_delivery === 1 && (
                    <>
                      <tr>
                        <td className="text-left">
                          <b>หมายเหตุ:</b>
                        </td>
                        <td className="text-right">{this.props.value && this.props.value.status_delivery_remark_status1 ? this.props.value.status_delivery_remark_status1 : "-"}</td>
                      </tr>
                    </>
                  )}

                  {this.props.value.status_delivery === 3 && (
                    <>
                      <tr>
                        <td className="text-left">
                          <b>หมายเหตุ:</b>
                        </td>
                        <td className="text-right">{this.props.value && this.props.value.status_delivery_remark_status3 ? this.props.value.status_delivery_remark_status3 : "-"}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-center">
            <button className="btn btn-outline-primary px-3 mx-auto" onClick={this.props.onHide}>
              ปิดหน้าต่าง
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
// ประวัติการตรวจสอบการชำระเงิน
class ModalDetailsLog extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} size={"lg"}>
        <Modal.Header>
          <Modal.Title>
            <b className="text-18">ประวัติการตรวจสอบการชำระเงิน</b>
            <p className="text-header mb-0">
              {this.props.value && this.props.value.detail && format_date(this.props.value.detail.datetime_create)} เขตเวลา {this.props.value && this.props.value.detail && this.props.value.detail.time_zone}: Edited by {this.props.value && this.props.value.detail && this.props.value.detail.username_create}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* เลขที่เอกสาร */}
          <div className="w-100 d-flex row mx-0 mb-2">
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">เลขที่เอกสาร:</label>
                <label>{this.props.value ? (this.props.value.ab_number ? this.props.value.ab_number : this.props.value.est_number ? this.props.value.est_number : this.props.value.detail ? this.props.value.detail.bf_order_main_number : "") : ""}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className={this.props.value && this.props.value.user_admin_check ? "card bg-yellow-dark text-center p-2 border border-secondary" : "card bg-secondary-light text-center p-2 border border-secondary"}>
                <label className="text-14 text-detail-name">พนักงานขาย:</label>
                <label>{this.props.value && this.props.value.user_admin}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className={this.props.value && this.props.value.user_admin_code_check ? "card bg-yellow-dark text-center p-2 border border-secondary" : "card bg-secondary-light text-center p-2 border border-secondary"}>
                <label className="text-14 text-detail-name">เลขที่ POS:</label>
                <label>{this.props.value && this.props.value.user_admin_code}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className={this.props.value && this.props.value.user_name_member_check ? "card bg-yellow-dark text-center p-2 border border-secondary" : "card bg-secondary-light text-center p-2 border border-secondary"}>
                <label className="text-14 text-detail-name">ลูกค้า:</label>
                <label>{this.props.value && this.props.value.user_name_member}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className={this.props.value && this.props.value.bf_payment_type_layer_1 !== this.props.value && this.props.value.af_payment_type_layer_1 ? "card bg-yellow-dark text-center p-2 border border-secondary" : "card bg-secondary-light text-center p-2 border border-secondary"}>
                <label className="text-14 text-detail-name">ประเภทชำระ:</label>
                <label>
                  {this.props.value && this.props.value.bf_payment_type_layer_1 !== this.props.value && this.props.value.af_payment_type_layer_1
                    ? this.props.value && this.props.value.af_payment_type_layer_1 === 0
                      ? "รอชำระ"
                      : this.props.value && this.props.value.af_payment_type_layer_1 === 1
                      ? "เงินสด"
                      : this.props.value && this.props.value.af_payment_type_layer_1 === 2
                      ? "เงินเชื่อ"
                      : this.props.value && this.props.value.af_payment_type_layer_1 === 3
                      ? "เงินโอน"
                      : this.props.value && this.props.value.af_payment_type_layer_1 === 4
                      ? "เป็นงวด"
                      : this.props.value && this.props.value.af_payment_type_layer_1 === 5
                      ? "เงินสด / โอน"
                      : "-"
                    : this.props.value && this.props.value.bf_payment_type_layer_1 === 0
                    ? "รอชำระ"
                    : this.props.value && this.props.value.bf_payment_type_layer_1 === 1
                    ? "เงินสด"
                    : this.props.value && this.props.value.bf_payment_type_layer_1 === 2
                    ? "เงินเชื่อ"
                    : this.props.value && this.props.value.bf_payment_type_layer_1 === 3
                    ? "เงินโอน"
                    : this.props.value && this.props.value.bf_payment_type_layer_1 === 4
                    ? "เป็นงวด"
                    : this.props.value && this.props.value.bf_payment_type_layer_1 === 5
                    ? "เงินสด / โอน"
                    : "-"}
                </label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className={this.props.value && this.props.value.datetime_created_check ? "card bg-yellow-dark text-center p-2 border border-secondary" : "card bg-secondary-light text-center p-2 border border-secondary"}>
                <label className="text-14 text-detail-name">วันและเวลา:</label>
                <label>{this.props.value && format_date(this.props.value.datetime_created)}</label>
              </div>
            </div>
          </div>

          <div className="w-100 mb-3 overflow-auto" style={{ maxHeight: "55vh" }}>
            <div className="table-responsive rounded-16">
              <table className="table table-sm table-striped table-borderless">
                <thead>
                  <tr className="rounded bg-primary text-white">
                    <th className="text-center">
                      <b>ลำดับ</b>
                    </th>
                    <th className="text-left" style={{ minWidth: 100 }}>
                      <b>ชื่อสินค้า</b>
                    </th>
                    <th className="text-center" style={{ minWidth: 100 }}>
                      <b>รายละเอียด</b>
                    </th>
                    <th className="text-center" style={{ minWidth: 80 }}>
                      <b>ราคา/หน่วย</b>
                    </th>
                    <th className="text-center" style={{ minWidth: 80 }}>
                      <b>จำนวนหลา</b>
                    </th>
                    <th className="text-center" style={{ minWidth: 80 }}>
                      <b>จำนวนไม้</b>
                    </th>
                    <th className="text-right" style={{ minWidth: 80 }}>
                      <b>รวมสุทธิ</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.value &&
                    this.props.value.history_transaction_list.transaction_list.map((item, index) => (
                      <tr className={item.create ? "bg-success" : item.update ? "bg-yellow-dark" : item.delete ? "bg-danger" : ""} key={index}>
                        <td className="text-center">{Number(index + 1)}</td>
                        <td className="text-left">{item.product_name}</td>
                        <td className="text-center">{item.detail_yard_list && item.detail_yard_list.toString().replaceAll(",", ", ")}</td>
                        <td className="text-center">{baht(item.price_by_item)}</td>
                        <td className="text-center">{item.yard_qty && baht(item.yard_qty.toFixed(2))}</td>
                        <td className="text-center">{number(item.qty)}</td>
                        <td className="text-right">{baht(item.total_price)}</td>
                      </tr>
                    ))}
                  <tr>
                    <td className="text-left" colSpan="4">
                      <b>รวมทั้งหมด</b>
                    </td>
                    <td className="text-center">
                      <b>{this.props.value && this.props.value.history_transaction_list.transaction_list ? number(total(this.props.value.history_transaction_list.transaction_list, "yard_qty")) : "0.00"} หลา</b>
                    </td>
                    <td className="text-center">
                      <b>{this.props.value && this.props.value.history_transaction_list.transaction_list ? number(total(this.props.value.history_transaction_list.transaction_list, "qty")) : "0"} ไม้</b>
                    </td>
                    <td className="text-right"></td>
                  </tr>
                  <tr>
                    <td>รายการ</td>
                    <td>{this.props.value && this.props.value.history_transaction_list.transaction_list.length}</td>
                    <td></td>
                    <td className="text-right" colSpan="3">
                      ภาษีมูลค่าเพิ่ม
                    </td>
                    <td className={this.props.value && this.props.value.bf_vat !== this.props.value && this.props.value.af_vat ? "text-right bg-yellow-dark" : "text-right"}>
                      <b>{this.props.value && this.props.value.bf_vat !== this.props.value && this.props.value.af_vat ? this.props.value && baht(this.props.value.af_vat) : this.props.value && baht(this.props.value.bf_vat)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td className="wpx-100">ที่อยู่จัดส่ง</td>
                    <td className={this.props.value && this.props.value.delivery_address_check ? "text-left bg-yellow-dark" : "text-left"} colSpan="3">
                      {this.props.value && this.props.value.delivery_address ? this.props.value.delivery_address : "-"}
                    </td>
                    <td className="text-right" colSpan="2">
                      รวมค่าสินค้า(รวมภาษี)
                    </td>
                    <td className={this.props.value && this.props.value.bf_total_price !== this.props.value && this.props.value.af_total_price ? "text-right bg-yellow-dark" : "text-right"}>
                      <b>{this.props.value && this.props.value.bf_total_price !== this.props.value && this.props.value.af_total_price ? this.props.value && baht(this.props.value.af_total_price) : this.props.value && baht(this.props.value.bf_total_price)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>หมายเหตุ</td>
                    <td className={this.props.value && this.props.value.remark_check ? "text-left bg-yellow-dark" : "text-left"} colSpan="3">
                      {this.props.value && this.props.value.remark ? this.props.value.remark : "-"}
                    </td>
                    <td className="text-right" colSpan="2">
                      ค่ามัดจำ
                    </td>
                    <td className={this.props.value && this.props.value.bf_deposit !== this.props.value && this.props.value.af_deposit ? "text-right bg-yellow-dark" : "text-right"}>
                      <b>{this.props.value && this.props.value.bf_deposit !== this.props.value && this.props.value.af_deposit ? this.props.value && baht(this.props.value.af_deposit) : this.props.value && baht(this.props.value.bf_deposit)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td colSpan="3"></td>
                    <td className="text-right" colSpan="2">
                      ยอดเงินหลังหักมัดจำ
                    </td>
                    <td className={this.props.value && this.props.value.bf_total_price !== this.props.value && this.props.value.af_total_price ? "text-right bg-yellow-dark" : "text-right"}>
                      <b>{this.props.value && this.props.value.bf_total_price !== this.props.value && this.props.value.af_total_price ? this.props.value && this.props.value && baht(Number(this.props.value.af_total_price - this.props.value.bf_deposit)) : this.props.value && baht(Number(this.props.value.bf_total_price - this.props.value.bf_deposit))}</b>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td colSpan="3"></td>
                    <td className="text-right" colSpan="2">
                      ส่วนลด
                    </td>
                    <td className={this.props.value && this.props.value.bf_discount !== this.props.value && this.props.value.af_discount ? "text-right bg-yellow-dark" : "text-right"}>
                      <b>{this.props.value && this.props.value.bf_discount !== this.props.value && this.props.value.af_discount ? this.props.value && baht(this.props.value.af_discount) : this.props.value && baht(this.props.value.bf_discount)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td colSpan="3"></td>
                    <td className="text-right" colSpan="2">
                      ค่าบริการจัดส่ง
                    </td>
                    <td className={this.props.value && this.props.value.bf_delivery_price !== this.props.value && this.props.value.af_delivery_price ? "text-right bg-yellow-dark" : "text-right"}>
                      <b>{this.props.value && this.props.value.bf_delivery_price !== this.props.value && this.props.value.af_delivery_price ? this.props.value && baht(this.props.value.af_delivery_price) : this.props.value && baht(this.props.value.bf_delivery_price)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td colSpan="3"></td>
                    <td className="text-right text-danger" colSpan="2">
                      ใช้เงินสะสม
                    </td>
                    <td className="text-right text-danger">
                      <b>0.00</b>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td colSpan="3"></td>
                    <td className="text-right" colSpan="2">
                      ยอดรวม
                    </td>
                    <td className={this.props.value && this.props.value.bf_total_price_final !== this.props.value && this.props.value.af_total_price_final ? "text-right bg-yellow-dark" : "text-right"}>
                      <b>{this.props.value && this.props.value.bf_total_price_final !== this.props.value && this.props.value.af_total_price_final ? this.props.value && baht(this.props.value.af_total_price_final) : this.props.value && baht(this.props.value.bf_total_price_final)}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-100 text-center">
            <button className="btn btn-outline-primary px-3 mx-auto" onClick={this.props.onHide}>
              ปิดหน้าต่าง
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
// ประวัติการตรวจสอบใบรับสินค้า
class ModalDetailsReceiptLog extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} size={"lg"}>
        <Modal.Header>
          <Modal.Title>
            <b className="text-18">ประวัติการตรวจสอบใบรับสินค้า</b>
            <p className="text-header mb-0">
              {this.props.value && this.props.value.detail && format_date(this.props.value.detail.datetime_create)} เขตเวลา {this.props.value && this.props.value.detail && this.props.value.detail.time_zone}: Edited by {this.props.value && this.props.value.detail && this.props.value.detail.username_create}
            </p>
            <p className="text-header mb-0">เลขที่เอกสาร: {this.props.value && this.props.value.bf_bill_receipt_code}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* เลขที่เอกสาร */}
          <div className="w-100 d-flex row mx-0 mb-3">
            <div className="col-6 col-sm-6 col-lg-3 col-xl-3 mb-2 px-1">
              <div className={this.props.value && this.props.value.date_time_receipt_check ? "card bg-yellow-dark text-center p-2 border border-secondary" : "card bg-secondary-light text-center p-2 border border-secondary"}>
                <label className="text-14 text-detail-name">วันที่รับ:</label>
                <label>{this.props.value && format_date_notime(this.props.value.date_time_receipt)}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-lg-3 col-xl-3 mb-2 px-1">
              <div className={this.props.value && this.props.value.company_supplier_check ? "card bg-yellow-dark text-center p-2 border border-secondary" : "card bg-secondary-light text-center p-2 border border-secondary"}>
                <label className="text-14 text-detail-name">ผู้จำหน่าย:</label>
                <label>{this.props.value && this.props.value.company_supplier ? this.props.value.company_supplier : "-"}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-lg-3 col-xl-3 mb-2 px-1">
              <div className={this.props.value && this.props.value.ref_receipt_code_check ? "card bg-yellow-dark text-center p-2 border border-secondary" : "card bg-secondary-light text-center p-2 border border-secondary"}>
                <label className="text-14 text-detail-name">เอกสารอ้างอิง</label>
                <label>{this.props.value && this.props.value.ref_receipt_code ? this.props.value.ref_receipt_code : "-"}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-lg-3 col-xl-3 mb-2 px-1">
              <div className={this.props.value && this.props.value.warehouse_name_check ? "card bg-yellow-dark text-center p-2 border border-secondary" : "card bg-secondary-light text-center p-2 border border-secondary"}>
                <label className="text-14 text-detail-name">โกดัง</label>
                <label>{this.props.value && this.props.value.warehouse_name}</label>
              </div>
            </div>
          </div>

          <div className="w-100 mb-3 overflow-auto" style={{ maxHeight: "50vh" }}>
            <div className="table-responsive rounded-16">
              <table className="table table-sm table-striped table-borderless">
                <thead>
                  <tr className="rounded bg-primary text-white">
                    <th className="text-center">
                      <b>ลำดับ</b>
                    </th>
                    <th className="text-center" style={{ minWidth: 80 }}>
                      <b>รหัสสินค้า</b>
                    </th>
                    <th className="text-left" style={{ minWidth: 120 }}>
                      <b>ชื่อสินค้า</b>
                    </th>
                    <th className="text-center">
                      <b>จำนวน(ไม้)</b>
                    </th>
                    <th className="text-right" style={{ minWidth: 80 }}>
                      <b>จำนวนหลา</b>
                    </th>
                    <th className="text-right" style={{ minWidth: 80 }}>
                      <b>ราคาที่รับมา</b>
                    </th>
                    <th className="text-right" style={{ minWidth: 80 }}>
                      <b>ยอดรวมสุทธิ</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.value &&
                    this.props.value.history_transaction_list.transaction_list.map((item, index) => (
                      <tr className={item.create ? "bg-success" : item.update ? "bg-yellow-dark" : item.delete ? "bg-danger" : ""} key={index}>
                        <td className="text-center">{Number(index + 1)}</td>
                        <td className="text-center">{item.relation_all_stock && item.relation_all_stock.product_main_data.product_code}</td>
                        <td className="text-left">{item.relation_all_stock && item.relation_all_stock.product_main_data.product_name}</td>
                        <td className="text-center">{number(item.qty)}</td>
                        <td className="text-right">{baht(item.yard_qty)}</td>
                        <td className="text-right">{baht(item.price_by_item)}</td>
                        <td className="text-right">{baht(item.total_price_by_item)}</td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    <td className="text-left wpx-100">
                      <b>ยอดรวม</b>
                    </td>
                    <td className="text-center">
                      <b>{this.props.value && this.props.value.history_transaction_list.transaction_list ? number(total(this.props.value.history_transaction_list.transaction_list, "qty")) : "0"} ไม้</b>
                    </td>
                    <td className="text-right">
                      <b>{this.props.value && this.props.value.history_transaction_list.transaction_list ? baht(total(this.props.value.history_transaction_list.transaction_list, "yard_qty")) : "0.00"} หลา</b>
                    </td>
                    <td></td>
                    <td className="text-right wpx-170">
                      <b>{this.props.value && this.props.value.history_transaction_list.transaction_list ? baht(total(this.props.value.history_transaction_list.transaction_list, "total_price_by_item")) : "0.00"} บาท</b>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div className="w-100 text-center">
            <button className="btn btn-outline-primary px-3 mx-auto" onClick={this.props.onHide}>
              ปิดหน้าต่าง
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
// ประวัติการตรวจสอบคืนสินค้า
class ModalDetailsRefundLog extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} size={"lg"}>
        <Modal.Header>
          <Modal.Title>
            <b className="text-18">ประวัติการตรวจสอบคืนสินค้า</b>
            <p className="text-header mb-0">
              {this.props.value && this.props.value.detail && format_date(this.props.value.detail.datetime_create)} เขตเวลา {this.props.value && this.props.value.detail && this.props.value.detail.time_zone}: Edited by {this.props.value && this.props.value.detail && this.props.value.detail.username_create}
            </p>
            <p className="text-header mb-0">เลขที่เอกสาร: {this.props.value && this.props.value.bf_order_main_number}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* เลขที่เอกสาร */}
          <div className="w-100 d-flex row mx-0 mb-3">
            <div className="col-6 col-sm-6 col-lg-6 col-xl-6 mb-2 px-1">
              <div className={this.props.value && this.props.value.datetime_created_check ? "card bg-yellow-dark text-center p-2 border border-secondary" : "card bg-secondary-light text-center p-2 border border-secondary"}>
                <label className="text-14 text-detail-name">วันที่:</label>
                <label>{this.props.value && format_date_notime(this.props.value.datetime_created)}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-lg-6 col-xl-6 mb-2 px-1">
              <div className={this.props.value && this.props.value.user_member_check ? "card bg-yellow-dark text-center p-2 border border-secondary" : "card bg-secondary-light text-center p-2 border border-secondary"}>
                <label className="text-14 text-detail-name">ชื่อลูกค้า:</label>
                <label>{this.props.value && this.props.value.user_member ? this.props.value.user_member : "-"}</label>
              </div>
            </div>
          </div>

          <div className="w-100 mb-3 overflow-auto" style={{ maxHeight: "50vh" }}>
            <div className="table-responsive rounded-16">
              <table className="table table-striped table-borderless">
                <thead>
                  <tr className="rounded bg-primary text-white">
                    <th className="text-left">
                      <b>เลขที่เอกสาร</b>
                    </th>
                    <th className="text-left" style={{ minWidth: 70 }}>
                      <b>ชื่อสินค้า</b>
                    </th>
                    <th className="text-center">
                      <b>จำนวนหลาที่คืน</b>
                    </th>
                    <th className="text-center">
                      <b>จำนวนไม้ที่คืน</b>
                    </th>
                    <th className="text-center">
                      <b>รวม</b>
                    </th>
                    <th className="text-right">
                      <b>ราคาต่อหน่วย</b>
                    </th>
                    <th className="text-right">
                      <b>ยอดรวมสุทธิ</b>
                    </th>
                  </tr>
                </thead>

                {this.props.value &&
                  this.props.value.history_transaction_list.transaction_list.map((item, index) => (
                    <>
                      <tbody>
                        <tr key={index}>
                          <td className={"text-center bg-light-gray pointer"} style={{ minWidth: 100, maxHeight: 100 }}>
                            {item.invoice_number}
                          </td>
                          <td className={"text-left bg-light-gray"} style={{ minWidth: 200, maxHeight: 200 }}></td>
                          <td className={"text-center  bg-light-gray"}></td>
                          <td className={"text-center  bg-light-gray"}></td>
                          <td className={"text-center  bg-light-gray"}></td>
                          <td className={"text-center  bg-light-gray"}></td>
                          <td className={"text-center  bg-light-gray"}></td>
                        </tr>
                        {item.refund_list &&
                          item.refund_list.map((refund_list, order_list_index) => (
                            <tr className={refund_list.create ? "bg-success" : refund_list.update ? "bg-yellow-dark" : refund_list.delete ? "bg-danger" : ""} key={order_list_index}>
                              <td className={"text-center"}></td>
                              <td className="text-left">{refund_list.product_name}</td>
                              <td className="text-center">{refund_list.yard_detail ? refund_list.yard_detail.toString().replaceAll(",", ", ") : ""}</td>
                              <td className="text-center">{refund_list.wood_qty}</td>
                              <td className="text-center">{baht(refund_list.yard_qty)}</td>
                              <td className="text-right">{baht(refund_list.price_by_item)}</td>
                              <td className="text-right">{baht(refund_list.yard_qty * refund_list.price_by_item)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </>
                  ))}
              </table>
            </div>
          </div>
          <div className="w-100 text-center">
            <button className="btn btn-outline-primary px-3 mx-auto" onClick={this.props.onHide}>
              ปิดหน้าต่าง
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
// ประวัติการตรวจสอบการชำระเงิน2
class ModalDetailsLog2 extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} size={"lg"}>
        <Modal.Header>
          <Modal.Title>
            <b className="text-18">ประวัติการตรวจสอบการชำระเงิน</b>
            <p className="text-header mb-0">
              {this.props.value && this.props.value.detail && format_date(this.props.value.detail.datetime_create)} เขตเวลา {this.props.value && this.props.value.detail && this.props.value.detail.time_zone}: Edited by {this.props.value && this.props.value.detail && this.props.value.detail.username_create}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* เลขที่เอกสาร */}
          <div className="w-100 d-flex row mx-0 mb-2">
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">เลขที่เอกสาร:</label>
                <label>{this.props.value ? (this.props.value.ab_number ? this.props.value.ab_number : this.props.value.est_number ? this.props.value.est_number : this.props.value.detail ? this.props.value.detail.bf_order_main_number : "") : ""}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className={"card bg-secondary-light text-center p-2 border border-secondary"}>
                <label className="text-14 text-detail-name">พนักงานขาย:</label>
                <label>{this.props.value && this.props.value.user_admin}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className={"card bg-secondary-light text-center p-2 border border-secondary"}>
                <label className="text-14 text-detail-name">เลขที่ POS:</label>
                <label>{this.props.value && this.props.value.user_admin_code}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className={"card bg-secondary-light text-center p-2 border border-secondary"}>
                <label className="text-14 text-detail-name">ลูกค้า:</label>
                <label>{this.props.value && this.props.value.user_name_member}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className="card bg-secondary-light text-center p-2 border border-secondary">
                <label className="text-14 text-detail-name">ประเภทชำระ:</label>
                <label>
                  {this.props.value && this.props.value.bf_payment_type_layer_1 !== this.props.value && this.props.value.af_payment_type_layer_1
                    ? this.props.value && this.props.value.af_payment_type_layer_1 === 0
                      ? "รอชำระ"
                      : this.props.value && this.props.value.af_payment_type_layer_1 === 1
                      ? "เงินสด"
                      : this.props.value && this.props.value.af_payment_type_layer_1 === 2
                      ? "เงินเชื่อ"
                      : this.props.value && this.props.value.af_payment_type_layer_1 === 3
                      ? "เงินโอน"
                      : this.props.value && this.props.value.af_payment_type_layer_1 === 4
                      ? "เป็นงวด"
                      : this.props.value && this.props.value.af_payment_type_layer_1 === 5
                      ? "เงินสด / โอน"
                      : "-"
                    : this.props.value && this.props.value.bf_payment_type_layer_1 === 0
                    ? "รอชำระ"
                    : this.props.value && this.props.value.bf_payment_type_layer_1 === 1
                    ? "เงินสด"
                    : this.props.value && this.props.value.bf_payment_type_layer_1 === 2
                    ? "เงินเชื่อ"
                    : this.props.value && this.props.value.bf_payment_type_layer_1 === 3
                    ? "เงินโอน"
                    : this.props.value && this.props.value.bf_payment_type_layer_1 === 4
                    ? "เป็นงวด"
                    : this.props.value && this.props.value.bf_payment_type_layer_1 === 5
                    ? "เงินสด / โอน"
                    : "-"}
                </label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-2 px-1">
              <div className={"card bg-secondary-light text-center p-2 border border-secondary"}>
                <label className="text-14 text-detail-name">วันและเวลา:</label>
                <label>{this.props.value && format_date(this.props.value.datetime_created)}</label>
              </div>
            </div>
          </div>

          <div className="w-100 mb-3 overflow-auto" style={{ maxHeight: "55vh" }}>
            <div className="table-responsive rounded-16">
              <table className="table table-sm table-striped table-borderless">
                <thead>
                  <tr className="rounded bg-primary text-white">
                    <th className="text-center">
                      <b>ลำดับ</b>
                    </th>
                    <th className="text-left" style={{ minWidth: 100 }}>
                      <b>ชื่อสินค้า</b>
                    </th>
                    <th className="text-center" style={{ minWidth: 100 }}>
                      <b>รายละเอียด</b>
                    </th>
                    <th className="text-center" style={{ minWidth: 80 }}>
                      <b>ราคา/หน่วย</b>
                    </th>
                    <th className="text-center" style={{ minWidth: 80 }}>
                      <b>จำนวนหลา</b>
                    </th>
                    <th className="text-center" style={{ minWidth: 80 }}>
                      <b>จำนวนไม้</b>
                    </th>
                    <th className="text-right" style={{ minWidth: 80 }}>
                      <b>รวมสุทธิ</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.value &&
                    this.props.value.history_transaction_list.transaction_list.map((item, index) => (
                      <tr key={index}>
                        <td className="text-center">{Number(index + 1)}</td>
                        <td className="text-left">{item.product_name}</td>
                        <td className="text-center">{item.detail_yard_list && item.detail_yard_list.toString().replaceAll(",", ", ")}</td>
                        <td className="text-center">{baht(item.price_by_item)}</td>
                        <td className="text-center">{item.yard_qty && baht(item.yard_qty.toFixed(2))}</td>
                        <td className="text-center">{number(item.qty)}</td>
                        <td className="text-right">{baht(item.total_price)}</td>
                      </tr>
                    ))}
                  <tr>
                    <td className="text-left" colSpan="4">
                      <b>รวมทั้งหมด</b>
                    </td>
                    <td className="text-center">
                      <b>{this.props.value && this.props.value.history_transaction_list.transaction_list ? number(total(this.props.value.history_transaction_list.transaction_list, "yard_qty")) : "0.00"} หลา</b>
                    </td>
                    <td className="text-center">
                      <b>{this.props.value && this.props.value.history_transaction_list.transaction_list ? number(total(this.props.value.history_transaction_list.transaction_list, "qty")) : "0"} ไม้</b>
                    </td>
                    <td className="text-right"></td>
                  </tr>
                  <tr>
                    <td>รายการ</td>
                    <td>{this.props.value && this.props.value.history_transaction_list.transaction_list.length}</td>
                    <td></td>
                    <td className="text-right" colSpan="3">
                      ภาษีมูลค่าเพิ่ม
                    </td>
                    <td className={"text-right"}>
                      <b>{this.props.value && this.props.value.bf_vat !== this.props.value && this.props.value.af_vat ? this.props.value && baht(this.props.value.af_vat) : this.props.value && baht(this.props.value.bf_vat)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td className="wpx-100">ที่อยู่จัดส่ง</td>
                    <td className={"text-left"} colSpan="3">
                      {this.props.value && this.props.value.delivery_address ? this.props.value.delivery_address : "-"}
                    </td>
                    <td className="text-right" colSpan="2">
                      รวมค่าสินค้า(รวมภาษี)
                    </td>
                    <td className={"text-right"}>
                      <b>{this.props.value && this.props.value.bf_total_price !== this.props.value && this.props.value.af_total_price ? this.props.value && baht(this.props.value.af_total_price) : this.props.value && baht(this.props.value.bf_total_price)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>หมายเหตุ</td>
                    <td className={"text-left"} colSpan="3">
                      {this.props.value && this.props.value.remark ? this.props.value.remark : "-"}
                    </td>
                    <td className="text-right" colSpan="2">
                      ค่ามัดจำ
                    </td>
                    <td className={"text-right"}>
                      <b>{this.props.value && this.props.value.bf_deposit !== this.props.value && this.props.value.af_deposit ? this.props.value && baht(this.props.value.af_deposit) : this.props.value && baht(this.props.value.bf_deposit)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td colSpan="3"></td>
                    <td className="text-right" colSpan="2">
                      ยอดเงินหลังหักมัดจำ
                    </td>
                    <td className={"text-right"}>
                      <b>{this.props.value && this.props.value.bf_total_price !== this.props.value && this.props.value.af_total_price ? this.props.value && this.props.value && baht(Number(this.props.value.af_total_price - this.props.value.bf_deposit)) : this.props.value && baht(Number(this.props.value.bf_total_price - this.props.value.bf_deposit))}</b>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td colSpan="3"></td>
                    <td className="text-right" colSpan="2">
                      ส่วนลด
                    </td>
                    <td className={"text-right"}>
                      <b>{this.props.value && this.props.value.bf_discount !== this.props.value && this.props.value.af_discount ? this.props.value && baht(this.props.value.af_discount) : this.props.value && baht(this.props.value.bf_discount)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td colSpan="3"></td>
                    <td className="text-right" colSpan="2">
                      ค่าบริการจัดส่ง
                    </td>
                    <td className={"text-right"}>
                      <b>{this.props.value && this.props.value.bf_delivery_price !== this.props.value && this.props.value.af_delivery_price ? this.props.value && baht(this.props.value.af_delivery_price) : this.props.value && baht(this.props.value.bf_delivery_price)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td colSpan="3"></td>
                    <td className="text-right text-danger" colSpan="2">
                      ใช้เงินสะสม
                    </td>
                    <td className="text-right text-danger">
                      <b>0.00</b>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td colSpan="3"></td>
                    <td className="text-right" colSpan="2">
                      ยอดรวม
                    </td>
                    <td className={"text-right"}>
                      <b>{this.props.value && this.props.value.bf_total_price_final !== this.props.value && this.props.value.af_total_price_final ? this.props.value && baht(this.props.value.af_total_price_final) : this.props.value && baht(this.props.value.bf_total_price_final)}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-100 text-center">
            <button className="btn btn-outline-primary px-3 mx-auto" onClick={this.props.onHide}>
              ปิดหน้าต่าง
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
// ประวัติการตรวจสอบใบรับสินค้า2
class ModalDetailsReceiptLog2 extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} size={"lg"}>
        <Modal.Header>
          <Modal.Title>
            <b className="text-18">ประวัติการตรวจสอบใบรับสินค้า</b>
            <p className="text-header mb-0">
              {this.props.value && this.props.value.detail && format_date(this.props.value.detail.datetime_create)} เขตเวลา {this.props.value && this.props.value.detail && this.props.value.detail.time_zone}: Edited by {this.props.value && this.props.value.detail && this.props.value.detail.username_create}
            </p>
            <p className="text-header mb-0">เลขที่เอกสาร: {this.props.value && this.props.value.bf_bill_receipt_code}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* เลขที่เอกสาร */}
          <div className="w-100 d-flex row mx-0 mb-3">
            <div className="col-6 col-sm-6 col-lg-3 col-xl-3 mb-2 px-1">
              <div className={"card bg-secondary-light text-center p-2 border border-secondary"}>
                <label className="text-14 text-detail-name">วันที่รับ:</label>
                <label>{this.props.value && format_date_notime(this.props.value.date_time_receipt)}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-lg-3 col-xl-3 mb-2 px-1">
              <div className={"card bg-secondary-light text-center p-2 border border-secondary"}>
                <label className="text-14 text-detail-name">ผู้จำหน่าย:</label>
                <label>{this.props.value && this.props.value.company_supplier ? this.props.value.company_supplier : "-"}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-lg-3 col-xl-3 mb-2 px-1">
              <div className={"card bg-secondary-light text-center p-2 border border-secondary"}>
                <label className="text-14 text-detail-name">เอกสารอ้างอิง</label>
                <label>{this.props.value && this.props.value.ref_receipt_code ? this.props.value.ref_receipt_code : "-"}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-lg-3 col-xl-3 mb-2 px-1">
              <div className={"card bg-secondary-light text-center p-2 border border-secondary"}>
                <label className="text-14 text-detail-name">โกดัง</label>
                <label>{this.props.value && this.props.value.warehouse_name}</label>
              </div>
            </div>
          </div>

          <div className="w-100 mb-3 overflow-auto" style={{ maxHeight: "50vh" }}>
            <div className="table-responsive rounded-16">
              <table className="table table-sm table-striped table-borderless">
                <thead>
                  <tr className="rounded bg-primary text-white">
                    <th className="text-center">
                      <b>ลำดับ</b>
                    </th>
                    <th className="text-center" style={{ minWidth: 80 }}>
                      <b>รหัสสินค้า</b>
                    </th>
                    <th className="text-left" style={{ minWidth: 120 }}>
                      <b>ชื่อสินค้า</b>
                    </th>
                    <th className="text-center">
                      <b>จำนวน(ไม้)</b>
                    </th>
                    <th className="text-right" style={{ minWidth: 80 }}>
                      <b>จำนวนหลา</b>
                    </th>
                    <th className="text-right" style={{ minWidth: 80 }}>
                      <b>ราคาที่รับมา</b>
                    </th>
                    <th className="text-right" style={{ minWidth: 80 }}>
                      <b>ยอดรวมสุทธิ</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.value &&
                    this.props.value.history_transaction_list.transaction_list.map((item, index) => (
                      <tr key={index}>
                        <td className="text-center">{Number(index + 1)}</td>
                        <td className="text-center">{item.relation_all_stock && item.relation_all_stock.product_main_data.product_code}</td>
                        <td className="text-left">{item.relation_all_stock && item.relation_all_stock.product_main_data.product_name}</td>
                        <td className="text-center">{number(item.qty)}</td>
                        <td className="text-right">{baht(item.yard_qty)}</td>
                        <td className="text-right">{baht(item.price_by_item)}</td>
                        <td className="text-right">{baht(item.total_price_by_item)}</td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    <td className="text-left wpx-100">
                      <b>ยอดรวม</b>
                    </td>
                    <td className="text-center">
                      <b>{this.props.value && this.props.value.history_transaction_list.transaction_list ? number(total(this.props.value.history_transaction_list.transaction_list, "qty")) : "0"} ไม้</b>
                    </td>
                    <td className="text-right">
                      <b>{this.props.value && this.props.value.history_transaction_list.transaction_list ? baht(total(this.props.value.history_transaction_list.transaction_list, "yard_qty")) : "0.00"} หลา</b>
                    </td>
                    <td></td>
                    <td className="text-right wpx-170">
                      <b>{this.props.value && this.props.value.history_transaction_list.transaction_list ? baht(total(this.props.value.history_transaction_list.transaction_list, "total_price_by_item")) : "0.00"} บาท</b>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div className="w-100 text-center">
            <button className="btn btn-outline-primary px-3 mx-auto" onClick={this.props.onHide}>
              ปิดหน้าต่าง
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
// ประวัติการตรวจสอบคืนสินค้า2
class ModalDetailsRefundLog2 extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} size={"lg"}>
        <Modal.Header>
          <Modal.Title>
            <b className="text-18">ประวัติการตรวจสอบคืนสินค้า</b>
            <p className="text-header mb-0">
              {this.props.value && this.props.value.detail && format_date(this.props.value.detail.datetime_create)} เขตเวลา {this.props.value && this.props.value.detail && this.props.value.detail.time_zone}: Edited by {this.props.value && this.props.value.detail && this.props.value.detail.username_create}
            </p>
            <p className="text-header mb-0">เลขที่เอกสาร: {this.props.value && this.props.value.bf_order_main_number}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* เลขที่เอกสาร */}
          <div className="w-100 d-flex row mx-0 mb-3">
            <div className="col-6 col-sm-6 col-lg-6 col-xl-6 mb-2 px-1">
              <div className={"card bg-secondary-light text-center p-2 border border-secondary"}>
                <label className="text-14 text-detail-name">วันที่:</label>
                <label>{this.props.value && format_date_notime(this.props.value.datetime_created)}</label>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-lg-6 col-xl-6 mb-2 px-1">
              <div className={"card bg-secondary-light text-center p-2 border border-secondary"}>
                <label className="text-14 text-detail-name">ชื่อลูกค้า:</label>
                <label>{this.props.value && this.props.value.user_member ? this.props.value.user_member : "-"}</label>
              </div>
            </div>
          </div>

          <div className="w-100 mb-3 overflow-auto" style={{ maxHeight: "50vh" }}>
            <div className="table-responsive rounded-16">
              <table className="table table-striped table-borderless">
                <thead>
                  <tr className="rounded bg-primary text-white">
                    <th className="text-left">
                      <b>เลขที่เอกสาร</b>
                    </th>
                    <th className="text-left" style={{ minWidth: 70 }}>
                      <b>ชื่อสินค้า</b>
                    </th>
                    <th className="text-center">
                      <b>จำนวนหลาที่คืน</b>
                    </th>
                    <th className="text-center">
                      <b>จำนวนไม้ที่คืน</b>
                    </th>
                    <th className="text-center">
                      <b>รวม</b>
                    </th>
                    <th className="text-right">
                      <b>ราคาต่อหน่วย</b>
                    </th>
                    <th className="text-right">
                      <b>ยอดรวมสุทธิ</b>
                    </th>
                  </tr>
                </thead>

                {this.props.value &&
                  this.props.value.history_transaction_list.transaction_list.map((item, index) => (
                    <>
                      <tbody>
                        <tr key={index}>
                          <td className={"text-center bg-light-gray pointer"} style={{ minWidth: 100, maxHeight: 100 }}>
                            {item.invoice_number}
                          </td>
                          <td className={"text-left bg-light-gray"} style={{ minWidth: 200, maxHeight: 200 }}></td>
                          <td className={"text-center  bg-light-gray"}></td>
                          <td className={"text-center  bg-light-gray"}></td>
                          <td className={"text-center  bg-light-gray"}></td>
                          <td className={"text-center  bg-light-gray"}></td>
                          <td className={"text-center  bg-light-gray"}></td>
                        </tr>
                        {item.refund_list &&
                          item.refund_list.map(
                            (refund_list, order_list_index) =>
                              refund_list.refund_list &&
                              refund_list.refund_list.map((refund_list2, order_list_index2) => (
                                <tr key={order_list_index}>
                                  <td className={"text-center"}></td>
                                  <td className="text-left">{refund_list2.product_name}</td>
                                  <td className="text-center">{refund_list2.yard_detail ? refund_list2.yard_detail.toString().replaceAll(",", ", ") : ""}</td>
                                  <td className="text-center">{refund_list2.wood_qty}</td>
                                  <td className="text-center">{baht(refund_list2.yard_qty)}</td>
                                  <td className="text-right">{baht(refund_list2.price_by_item)}</td>
                                  <td className="text-right">{baht(refund_list2.yard_qty * refund_list2.price_by_item)}</td>
                                </tr>
                              ))
                          )}
                      </tbody>
                    </>
                  ))}
              </table>
            </div>
          </div>
          <div className="w-100 text-center">
            <button className="btn btn-outline-primary px-3 mx-auto" onClick={this.props.onHide}>
              ปิดหน้าต่าง
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
function total(array, key) {
  if (key) {
    return Number(baht(array.reduce((a, b) => Number(a) + (Number(b[key]) || 0), 0)).replaceAll(",", ""));
  } else {
    return Number(baht(array.reduce((a, b) => Number(a) + Number(b), 0)).replaceAll(",", ""));
  }
}
const baht = (number) => {
  try {
    if (number) {
      return Number(number)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    } else {
      return Number(number).toFixed(2);
    }
  } catch (error) {}
};
const number = (number) => {
  try {
    if (number) {
      return Number(number).toLocaleString("en-US");
    } else {
      return Number(number);
    }
  } catch (error) {}
};
const status_bill = (status) => {
  switch (status) {
    case 1:
      return "ยังไม่ได้ชำระ";
    case 2:
      return "ยังไม่ได้ชำระ";
    case 3:
      return "ชำระเงินแล้ว";
    case 4:
      return "ยังไม่ได้ชำระ";
    case 5:
      return "ยังไม่ได้ชำระ";
    case 6:
      return "ยกเลิก";
    default:
      return "ยังไม่ได้ชำระ";
  }
};
function status_refund(status) {
  let style = "";
  let text = "";
  let icon = "";

  switch (status) {
    case 1:
      icon = "\uf017";
      text = "Pending...";
      style = "text-yellow-adjustment text-12 icon";
      break;
    case 2:
      icon = "\uf058";
      text = "Approved";
      style = "text-green-adjustment text-12 icon ";
      break;
    case 3:
      icon = "\uf057";
      text = "Reject";
      style = "text-danger text-12 icon";
      break;
    default:
      text = "";
      style = "";
      break;
  }
  return (
    <span className={style}>
      <b>
        {icon} {text}
      </b>
    </span>
  );
}
function financial(number) {
  return Number.parseFloat(number).toFixed(2);
}
function print(docDefinition) {
  try {
    pdfMake.createPdf(docDefinition).open();
  } catch (error) {
    if (error === "Open PDF in new window blocked by browser") {
      alert("warning", "แจ้งเตือน", "ไม่สามารถเปิดเอกสารได้เนื่องจากการปิดกั้นจากบราวเซอร์");
    } else {
      alert("warning", "แจ้งเตือน", "กรุณาลองใหม่อีกครั้ง");
    }
  }
}
// รายงานสรุปยอดขายจากลูกค้ารายบุคคล
function report_customer(data, start_date, end_date, year) {
  var array = [];
  let years = Number(year) + 543;

  for (let d of data) {
    array.push([{ text: d.member_name, colSpan: 7, fillColor: "#dee2e6" }, "", "", "", "", "", ""]);
    for (let i of d.invoice_list) {
      array.push([{ text: "" }, { text: i.ab_number ? i.ab_number : "-", alignment: "left" }, { text: baht(i.total_price), alignment: "right" }, { text: baht(i.total_pay), alignment: "right" }, { text: baht(i.total_balance), alignment: "right" }, { text: i.discount ? baht(i.discount) : "0.00", alignment: "right" }, { text: status_bill(i.status_bill), alignment: "center" }]);
    }
    array.push([{ text: "ยอดรวม", colSpan: 2, bold: true }, "", { text: baht(d.total_price), alignment: "right", bold: true }, { text: baht(d.total_pay), alignment: "right", bold: true }, { text: baht(d.total_balance), alignment: "right", bold: true }, { text: baht(d.total_discount), alignment: "right", bold: true }, ""]);
  }
  array.push([{ text: "ยอดรวมทั้งหมด", colSpan: 2, bold: true }, "", { text: baht(total(data, "total_price")), alignment: "right", bold: true }, { text: baht(total(data, "total_pay")), alignment: "right", bold: true }, { text: baht(total(data, "total_balance")), alignment: "right", bold: true }, { text: baht(total(data, "total_discount")), alignment: "right", bold: true }, ""]);
  var docDefinition = {
    content: [
      {
        columns: [
          { width: 200, text: "AIMHENGJAN", fontSize: 18, bold: true, color: "#4E6FFB" },
          { width: "*", text: "รายงานสรุปยอดขายจากลูกค้ารายบุคคล", alignment: "right", fontSize: 18, bold: true },
        ],
      },
      { text: " " },
      {
        columns: [
          { width: 200, text: "", fontSize: 18, bold: true },
          {
            width: "*",
            text: year && start_date === "" && end_date === "" ? "ปี " + years : start_date !== "" && end_date !== "" ? "วันที่ " + format_date_report(start_date) + " ถึง " + format_date_report(end_date) : start_date !== "" && end_date === "" ? "วันที่ " + format_date_report(start_date) : null,
            alignment: "right",
            fontSize: 14,
          },
        ],
      },
      { text: " " },
      {
        table: {
          headerRows: 1,
          widths: ["*", "*", "*", "*", "*", "*", "*"],
          body: [
            [
              { text: "ชื่อ", bold: true, alignment: "left" },
              { text: "เลขเอกสาร", bold: true, alignment: "left" },
              { text: "รวมทั้งหมด", bold: true, alignment: "right" },
              { text: "ชำระแล้ว", bold: true, alignment: "right" },
              { text: "ยอดค้างชำระ", bold: true, alignment: "right" },
              { text: "ส่วนลด", bold: true, alignment: "right" },
              { text: "สถานะ", bold: true, alignment: "center" },
            ],
            ...array,
          ],
        },
      },
    ],
    defaultStyle: { font: "FCIconic", fontSize: 7, lineHeight: 0.8 },
    pageSize: "A4",
    pageMargins: [15, 15, 15, 15],
  };
  print(docDefinition, "รายงานสรุปยอดขายจากลูกค้ารายบุคคล " + new Date().toISOString() + ".pdf");
}
// รายงานผลรวมยอดขาย
function report_products(data, start_date, end_date, year) {
  var array = [];
  let years = Number(year) + 543;

  for (let item of data) {
    array.push([
      {
        text: item.type_product_name,
        colSpan: 9,
        fillColor: "#dee2e6",
      },
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ]);
    for (let product of item.product_list) {
      array.push([
        { text: product.product_name, alignment: "left" },
        { text: product.wood_qty, alignment: "center" },
        { text: baht(product.yard_qty), alignment: "right" },
        { text: baht(product.amount), alignment: "right" },
        { text: baht(product.sale_percent) + "%", alignment: "right" },
        { text: baht(product.avg_price), alignment: "right" },
        { text: baht(product.cost), alignment: "right" },
        { text: baht(product.gross_margin), alignment: "right" },
        { text: product.gross_margin_percent, alignment: "right" },
      ]);
    }
    array.push([{ text: "ยอดรวม", bold: true, alignment: "left" }, { text: item.wood_qty, bold: true, alignment: "center" }, { text: baht(item.yard_qty), bold: true, alignment: "right" }, { text: baht(item.amount), bold: true, alignment: "right" }, { text: baht(item.sale_percent) + "%", bold: true, alignment: "right" }, "", "", "", ""]);
  }

  array.push([{ text: "ยอดรวมทั้งหมด", bold: true, alignment: "left" }, { text: total(data, "wood_qty"), bold: true, alignment: "center" }, { text: baht(total(data, "yard_qty")), bold: true, alignment: "right" }, { text: baht(total(data, "amount")), bold: true, alignment: "right" }, { text: baht(total(data, "sale_percent")) + "%", bold: true, alignment: "right" }, "", "", "", ""]);

  var docDefinition = {
    content: [
      {
        columns: [
          { width: 200, text: "AIMHENGJAN", fontSize: 18, bold: true, color: "#4E6FFB" },
          { width: "*", text: "รายงานผลรวมยอดขาย", alignment: "right", fontSize: 18, bold: true },
        ],
      },
      { text: " " },
      {
        columns: [
          { width: 200, text: "", fontSize: 18, bold: true },
          {
            width: "*",
            text: year && start_date === "" && end_date === "" ? "ปี " + years : start_date !== "" && end_date !== "" ? "วันที่ " + format_date_report(start_date) + " ถึง " + format_date_report(end_date) : start_date !== "" && end_date === "" ? "วันที่ " + format_date_report(start_date) : null,
            alignment: "right",
            fontSize: 14,
          },
        ],
      },
      { text: " " },
      {
        table: {
          headerRows: 1,
          widths: ["20%", "7%", "11%", "11%", "10%", "10%", "10%", "10%", "11%"],
          body: [
            [
              { text: "Product", bold: true, alignment: "left" },
              { text: "ไม้", bold: true, alignment: "center" },
              { text: "หลา", bold: true, alignment: "right" },
              { text: "Amount", bold: true, alignment: "right" },
              { text: "% of Sales", bold: true, alignment: "center" },
              { text: "AVG Price", bold: true, alignment: "right" },
              { text: "Cos", bold: true, alignment: "right" },
              { text: "Gross Margin", bold: true, alignment: "right" },
              { text: "Gross Margin %", bold: true, alignment: "right" },
            ],
            ...array,
          ],
        },
      },
    ],
    defaultStyle: { font: "FCIconic", fontSize: 7, lineHeight: 0.8 },
    pageSize: "A4",
    pageMargins: [15, 15, 15, 15],
  };
  print(docDefinition, "รายงานผลรวมยอดขาย " + new Date().toISOString() + ".pdf");
}
//รายงานสรุปมูลค่าสินค้าคงคลัง
function report_inventory(data, start_date) {
  var array = [];
  for (let item of data) {
    array.push([{ text: item.type_product_name, colSpan: 4, fillColor: "#dee2e6" }, "", "", ""]);
    for (let product of item.product_list) {
      array.push([
        { text: product.product_name, alignment: "left" },
        { text: baht(product.yard_qty), alignment: "center" },
        { text: product.wood_qty, alignment: "center" },
        { text: baht(product.asset_value), alignment: "right" },
      ]);
    }
    array.push([
      { text: "ยอดรวม", bold: true, alignment: "left" },
      { text: baht(item.yard_qty), bold: true, alignment: "center" },
      { text: item.wood_qty, bold: true, alignment: "center" },
      { text: baht(item.asset_value), bold: true, alignment: "right" },
    ]);
  }
  array.push([
    { text: "ยอดรวมทั้งหมด", bold: true, alignment: "left" },
    { text: baht(total(data, "yard_qty")), bold: true, alignment: "center" },
    { text: total(data, "wood_qty"), bold: true, alignment: "center" },
    { text: baht(total(data, "asset_value")), bold: true, alignment: "right" },
  ]);

  var docDefinition = {
    content: [
      {
        columns: [
          { width: 200, text: "AIMHENGJAN", fontSize: 18, bold: true, color: "#4E6FFB" },
          { width: "*", text: "รายงานสรุปมูลค่าสินค้าคงคลัง", alignment: "right", fontSize: 18, bold: true },
        ],
      },
      { text: " " },
      {
        columns: [
          { width: 200, text: "", fontSize: 18, bold: true },
          {
            width: "*",
            text: "วันที่ " + format_date_report(start_date),
            alignment: "right",
            fontSize: 14,
          },
        ],
      },
      { text: " " },
      {
        table: {
          headerRows: 1,
          widths: ["*", "*", "*", "*"],
          body: [
            [
              { text: "Product", bold: true, alignment: "left" },
              { text: "จำนวนหลา", bold: true, alignment: "center" },
              { text: "จำนวนไม้คงเหลือ", bold: true, alignment: "center" },
              { text: "Asset Value", bold: true, alignment: "right" },
            ],
            ...array,
          ],
        },
      },
    ],
    defaultStyle: { font: "FCIconic", fontSize: 7, lineHeight: 0.8 },
    pageSize: "A4",
    pageMargins: [15, 15, 15, 15],
  };
  print(docDefinition, "รายงานสรุปมูลค่าสินค้าคงคลัง " + new Date().toISOString() + ".pdf");
}
//รายงานรายละเอียดยอดขาย
function report_products_invoice(data, start_date, end_date) {
  var array = [];
  for (let item of data) {
    array.push([{ text: item.date, alignment: "left", colSpan: 9, fillColor: "#dee2e6" }, "", "", "", "", "", "", "", ""]);

    for (let product of item.product_list) {
      array.push(["", { text: product.invoice_number, alignment: "left" }, { text: product.member_name, alignment: "left" }, { text: product.product_name, alignment: "left" }, { text: product.order_detail.toString().replaceAll("[", "").replaceAll("]", "").replaceAll(",", " "), alignment: "left" }, { text: baht(product.product_price), alignment: "right" }, { text: product.total_wood, alignment: "center" }, { text: baht(product.total_yard), alignment: "center" }, { text: baht(product.amount), alignment: "right" }]);
    }
    array.push([{ text: "ยอดรวม", colSpan: 5, bold: true, alignment: "left" }, "", "", "", "", { text: baht(item.product_price), bold: true, alignment: "right" }, { text: item.total_wood, bold: true, alignment: "center" }, { text: baht(item.total_yard), bold: true, alignment: "center" }, { text: baht(item.amount), bold: true, alignment: "right" }]);
  }

  array.push([{ text: "ยอดรวมทั้งหมด", colSpan: 5, bold: true, alignment: "left" }, "", "", "", "", { text: baht(total(data, "product_price")), bold: true, alignment: "right" }, { text: total(data, "total_wood"), bold: true, alignment: "center" }, { text: baht(total(data, "total_yard")), bold: true, alignment: "center" }, { text: baht(total(data, "amount")), bold: true, alignment: "right" }]);

  var docDefinition = {
    content: [
      {
        columns: [
          { width: 200, text: "AIMHENGJAN", fontSize: 18, bold: true, color: "#4E6FFB" },
          { width: "*", text: "รายงานรายละเอียดยอดขาย", alignment: "right", fontSize: 18, bold: true },
        ],
      },
      { text: " " },
      {
        columns: [
          { width: 200, text: "", fontSize: 18, bold: true },
          {
            width: "*",
            text: start_date !== "" && end_date !== "" ? "วันที่ " + format_date_report(start_date) + " ถึง " + format_date_report(end_date) : start_date !== "" && end_date === "" ? "วันที่ " + format_date_report(start_date) : null,
            alignment: "right",
            fontSize: 14,
          },
        ],
      },
      { text: " " },
      {
        table: {
          headerRows: 1,
          widths: ["8%", "11%", "11%", "16%", "16%", "10%", "8%", "10%", "10%"],

          body: [
            [
              { text: "วันที่", bold: true, alignment: "left" },
              { text: "หมายเลข", bold: true, alignment: "left" },
              { text: "ลูกค้า", bold: true, alignment: "left" },
              { text: "สินค้า / บริการ", bold: true, alignment: "left" },
              { text: "รายละเอียด", bold: true, alignment: "left" },
              { text: "Sales / Price", bold: true, alignment: "right" },
              { text: "ไม้", bold: true, alignment: "center" },
              { text: "หลา", bold: true, alignment: "center" },
              { text: "Amount", bold: true, alignment: "right" },
            ],
            ...array,
          ],
        },
      },
    ],
    defaultStyle: { font: "FCIconic", fontSize: 7, lineHeight: 0.8 },
    pageSize: "A4",
    pageMargins: [15, 15, 15, 15],
  };
  print(docDefinition, "รายงานรายละเอียดยอดขาย " + new Date().toISOString() + ".pdf");
}
// รายการโอน
function report_transfer(data, start_date, end_date, year) {
  var array = [];
  let years = Number(year) + 543;
  for (let i = 0; i < data.length; i++) {
    array.push([{ text: i + 1, alignment: "center" }, { text: format_date(data[i].record_date), alignment: "center" }, { text: data[i].customer_name, alignment: "left" }, { text: data[i].sign_name, alignment: "center" }, { text: baht(data[i].transfer_amount), alignment: "right" }, { stack: [data[i].bank_name, data[i].bank_number] }, { text: data[i].invoice_number, alignment: "left" }, { text: data[i].track_invoice ? data[i].track_invoice : "", alignment: "left" }, { text: data[i].sign_cut, alignment: "center" }, { text: data[i].check_bill, alignment: "center" }, { text: (data[i].remark ? data[i].remark : "") + " " + (data[i].payment_remark ? data[i].payment_remark : ""), alignment: "center" }]);
  }

  var docDefinition = {
    content: [
      {
        columns: [
          { width: 200, text: "", fontSize: 18, bold: true, color: "#4E6FFB" },
          { width: "*", text: "รายการโอน", alignment: "right", fontSize: 18, bold: true },
        ],
      },
      { text: " " },
      {
        columns: [
          { width: 200, text: "", fontSize: 18, bold: true },
          {
            width: "*",
            text: year && start_date === "" && end_date === "" ? "ปี " + years : start_date !== "" && end_date !== "" ? "วันที่ " + format_date_report(start_date) + " ถึง " + format_date_report(end_date) : start_date !== "" && end_date === "" ? "วันที่ " + format_date_report(start_date) : null,
            alignment: "right",
            fontSize: 14,
          },
        ],
      },
      { text: " " },
      {
        table: {
          headerRows: 1,
          widths: ["4%", "9%", "16%", "6%", "10%", "*", "11%", "11%", "6%", "6%", "6%"],
          body: [
            [
              { text: "No", bold: true, alignment: "center" },
              { text: "วันที่", bold: true, alignment: "center" },
              { text: "ชื่อลูกค้า", bold: true, alignment: "left" },
              { text: "เซ็นชื่อ", bold: true, alignment: "center" },
              { text: "ยอดโอนเงิน", bold: true, alignment: "right" },
              { text: "ชื่อบัญชี", bold: true, alignment: "left" },
              { text: "เลข Invoice", bold: true, alignment: "left" },
              { text: "ใบแจ้งหนี้", bold: true, alignment: "left" },
              { text: "เช็นตัด", bold: true, alignment: "center" },
              { text: "เช็คบิล", bold: true, alignment: "center" },
              { text: "หมายเหตุ", bold: true, alignment: "center" },
            ],
            ...array,
          ],
        },
      },
    ],
    defaultStyle: { font: "FCIconic", fontSize: 7, lineHeight: 1 },
    pageSize: "A4",
    pageMargins: [15, 15, 15, 15],
  };
  print(docDefinition, "รายการโอน " + new Date().toISOString() + ".pdf");
}
// ใบเสนอราคา
function report_1(data, message) {
  console.log("data:",data);
  let date = new Date(data.detail_print.datetime_create);
  let array_product = data.order_list;
  let array = [];
  for (let i = 0; i < array_product.length; i++) {
    array.push([
      { text: array_product[i].product_main.product_main_data.product_name, alignment: "left", border: [false, false, false, false] },
      { text: array_product[i].qty, alignment: "center", border: [false, false, false, false] },
      { text: array_product[i].detail_yard_list.toString().replaceAll(",", ", "), alignment: "left", border: [false, false, false, false] },
      {
        text: baht(array_product[i].price_by_item),
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: baht(array_product[i].yard_qty),
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: baht(array_product[i].total_price),
        alignment: "right",
        border: [false, false, false, false],
      },
    ]);
  }

  var docDefinition = {
    content: [
      {
        columns: [
          { width: 200, text: "", fontSize: 18, bold: true, color: "#4E6FFB" },
          { width: "*", text: "ใบเสนอราคา", alignment: "right", fontSize: 18, bold: true },
        ],
      },
      { text: " " },
      {
        columns: [
          {
            stack: [{ text: "ชื่อลูกค้า: " + data.member.full_name, fontSize: 14, bold: true }],
            alignment: "left",
          },
          {
            stack: [
              { text: "เลขที่: " + data.data_number.est_number, fontSize: 11, bold: true },
              {
                text: "วัน/เดือน/ปี: " + ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + (date.getFullYear() + 543),
                fontSize: 11,
                bold: true,
              },
            ],
            alignment: "right",
          },
        ],
      },
      { text: " " },
      {
        table: {
          headerRows: 1,
          widths: ["32%", "6%", "31%", "10%", "10%", "10%"],
          body: [
            [
              { text: "ชื่อสินค้า/บริการ", bold: true, alignment: "left", border: [false, true, false, true] },
              { text: "ไม้", bold: true, alignment: "center", border: [false, true, false, true] },
              { text: "รายละเอียด", bold: true, alignment: "left", border: [false, true, false, true] },
              { text: "ราคา", bold: true, alignment: "right", border: [false, true, false, true] },
              { text: "หลา", bold: true, alignment: "right", border: [false, true, false, true] },
              { text: "จำนวนเงิน", bold: true, alignment: "right", border: [false, true, false, true] },
            ],
            ...array,
            [
              { text: "รวม", bold: true, alignment: "right", border: [false, true, false, true] },
              {
                text: message === "sale" ? number(total(data.detail_print.order_list_data, "qty")) : number(total(data.detail_print.order_list, "qty")),
                alignment: "center",
                border: [false, true, false, true],
              },
              { text: "ไม้", bold: true, alignment: "left", border: [false, true, false, true], padding: [10, 0, 0, 0] },
              { text: "รวม", bold: true, alignment: "right", border: [false, true, false, true] },
              {
                text: message === "sale" ? baht(total(data.detail_print.order_list_data, "yard_qty")) : baht(total(data.detail_print.order_list, "yard_qty")),
                alignment: "right",
                bold: true,
                border: [false, true, false, true],
              },
              { text: "หลา", bold: true, alignment: "left", border: [false, true, false, true], padding: [10, 0, 0, 0] },
            ],

            [{ text: data.detail_print.remark ? "หมายเหตุ: " + data.detail_print.remark : "หมายเหตุ: -", colSpan: 3, rowSpan: 3, border: [false, true, false, false] }, "", "", { text: "รวม", alignment: "left", border: [false, true, false, false] }, { text: baht(data.detail_print.total_price_final), alignment: "right", border: [false, false, false, false], colSpan: 2 }, ""],
            [{ text: "", colSpan: 3, border: [false, false, false, false] }, "", "", { text: "มัดจำ", alignment: "left", border: [false, false, false, true] }, { text: baht(Number(data.detail_print.deposit)), alignment: "right", border: [false, false, false, false], colSpan: 2 }, ""],
            [{ text: "", colSpan: 3, border: [false, false, false, false] }, "", "", { text: "ส่วนลด", alignment: "left", border: [false, false, false, true] }, { text: baht(Number(data.detail_print.discount)), alignment: "right", border: [false, false, false, true], colSpan: 2 }, ""],
            [{ text: "", colSpan: 3, border: [false, false, false, false] }, "", "", { text: "รวมทั้งสิ้น", alignment: "left", fontSize: 11, bold: true, border: [false, false, false, false] }, { text: baht(Number(data.detail_print.total_price_final - data.detail_print.deposit)), fontSize: 11, bold: true, alignment: "right", border: [false, false, false, false], colSpan: 2, noWrap: true }, ""],
          ],
        },
        margin: [0, 0, 0, 173],
      },
      { text: " " },
      {
        text: "ชำระแล้วรบกวนแจ้ง",
        absolutePosition: { x: 15, y: 660 },
      },
      {
        text: "LINE : @RUF1270S",
        absolutePosition: { x: 15, y: 675 },
      },
      {
        text: "TEL/Line ID: 0864800270",
        absolutePosition: { x: 15, y: 690 },
      },
      { text: " " },
      {
        table: {
          headerRows: 1,
          widths: ["*", "*", "*"],
          body: [
            [
              { stack: [" ", ".........................................................................................", "ผู้เสนอราคา", "วันที่..........................................................."], alignment: "center", border: [false, true, false, false] },
              { stack: [" ", ".........................................................................................", "ผู้ส่งสินค้า", "วันที่..........................................................."], alignment: "center", border: [false, true, false, false] },
              { stack: [" ", ".........................................................................................", "ผู้รับสินค้า", "วันที่..........................................................."], alignment: "center", border: [false, true, false, false] },
            ],
          ],
        },
        absolutePosition: { x: 25, y: 710 },
      },
      {
        width: data.detail_print.status_bill === 3 ? 150 : data.detail_print.status_bill === 6 ? 150 : 0,
        svg: data.detail_print.status_bill === 3 ? '<svg width="150" height="150"><text transform="translate(100, 100) rotate(-45)" font-weight="bold" font-size="50" fill="none" stroke="black" stroke-width="1" text-anchor="middle" alignment-baseline="middle">PAID</text> </svg>' : data.detail_print.status_bill === 6 ? '<svg width="150" height="150"><text transform="translate(100, 100) rotate(-45)" font-weight="bold" font-size="30" fill="none" stroke="black" stroke-width="1" text-anchor="middle" alignment-baseline="middle">CANCEL</text> </svg>' : '<svg width="150" height="150"><text transform="translate(100, 100) rotate(-45)" font-weight="bold" font-size="30" fill="none" stroke="black" stroke-width="1" text-anchor="middle" alignment-baseline="middle"></text> </svg>',
        absolutePosition: { x: 180, y: 140 },
      },
    ],
    defaultStyle: { font: "FCIconic", fontSize: 10, lineHeight: 1 },
    pageSize: "A4",
    pageMargins: [12, 100, 12, 25],
  };
  print(docDefinition, "ใบเสนอราคา " + new Date().toISOString() + ".pdf");
}
// ใบเสนอราคา/ใบส่งสินค้าชั่วคราว
function report_2(data, message) {
  let array_product = data.order_list;
  let array = [];
  for (let i = 0; i < array_product.length; i++) {
    let text = "";
    for (let k = 0; k < array_product[i].detail_yard_list.length; k++) {
      if (k % 6 === 0 && k !== 0) {
        text += "\n";
        // yard.push({ columns: columns });
        // columns = [];
      }
      text += Number(array_product[i].detail_yard_list[k]) + ", ";
      // columns.push({ text: Number(array_product[i].detail_yard_list[k]) + (k === array_product[i].detail_yard_list.length - 1 ? "" : ", "), width: "auto", margin: [0, 0, 4, 0] })
    }
    // if (columns.length > 0) {
    //     yard.push({ columns: columns });
    // }
    array.push([
      { text: array_product[i].product_main.product_main_data.product_name, alignment: "left", border: [false, false, false, false] },
      { text: array_product[i].qty, alignment: "center", border: [false, false, false, false] },
      { text: text.slice(0, -2), alignment: "left", border: [false, false, false, false] },
      // { stack: yard, alignment: "left", border: [false, false, false, false] },
      {
        text: baht(array_product[i].price_by_item),
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: baht(array_product[i].yard_qty),
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: baht(array_product[i].total_price),
        alignment: "right",
        border: [false, false, false, false],
      },
    ]);
  }

  var docDefinition = {
    content: [
      {
        width: data.detail_print.status_bill === 3 ? 390 : data.detail_print.status_bill === 6 ? 390 : 0,
        svg: data.detail_print.status_bill === 3 ? '<svg width="390" height="390"><text transform="translate(260, 260) rotate(-40)" font-weight="bold" font-size="130" fill="none" stroke="black" stroke-width="1" text-anchor="middle" alignment-baseline="middle">PAID</text> </svg>' : data.detail_print.status_bill === 6 ? '<svg width="390" height="390"><text transform="translate(260, 260) rotate(-45)" font-weight="bold" font-size="100" fill="none" stroke="black" stroke-width="1" text-anchor="middle" alignment-baseline="middle">CANCEL</text> </svg>' : '<svg width="390" height="390"><text transform="translate(260, 260) rotate(-45)" font-weight="bold" font-size="100" fill="none" stroke="black" stroke-width="1" text-anchor="middle" alignment-baseline="middle"></text> </svg>',
        absolutePosition: { x: 45, y: 200 },
      },
      {
        columns: [
          { width: 200, text: "", fontSize: 18, bold: true, color: "#4E6FFB" },
          { width: "*", text: "ใบเสนอราคา/ใบส่งสินค้าชั่วคราว", alignment: "right", fontSize: 18, bold: true },
        ],
      },
      { text: " " },
      {
        columns: [
          {
            stack: [{ text: "ชื่อลูกค้า: " + data.member.full_name, fontSize: 14, bold: true }],
            alignment: "left",
          },
          {
            stack: [
              { text: "เลขที่: " + data.data_number.ab_number, fontSize: 11, bold: true },
              {
                text: "วัน/เดือน/ปี: " + (!data.detail_print.datetime_create ? (data.datetime_create ? format_date2(data.datetime_create) : format_date2(new Date())) : format_date2(data.detail_print.datetime_create)),
                fontSize: 11,
                bold: true,
              },
            ],
            alignment: "right",
          },
        ],
      },
      { text: " " },
      {
        // fillColor: "#333333",
        table: {
          headerRows: 1,
          widths: ["32%", "6%", "29%", "10%", "10%", "12%"],
          body: [
            [
              { text: "ชื่อสินค้า/บริการ", bold: true, alignment: "left", border: [false, true, false, true] },
              { text: "ไม้", bold: true, alignment: "center", border: [false, true, false, true] },
              { text: "รายละเอียด", bold: true, alignment: "left", border: [false, true, false, true] },
              { text: "ราคา", bold: true, alignment: "right", border: [false, true, false, true] },
              { text: "หลา", bold: true, alignment: "right", border: [false, true, false, true] },
              { text: "จำนวนเงิน", bold: true, alignment: "right", border: [false, true, false, true] },
            ],
            ...array,
            [
              { text: "รวม", alignment: "right", bold: true, border: [false, true, false, false] },
              {
                text: message === "sale" ? number(total(data.detail_print.order_list_data, "qty")) : number(total(data.detail_print.order_list, "qty")),
                bold: true,
                alignment: "center",
                border: [false, true, false, false],
                noWrap: true,
              },
              { text: "ไม้", alignment: "left", bold: true, border: [false, true, false, false], padding: [10, 0, 0, 0] },
              { text: "รวม", alignment: "right", bold: true, border: [false, true, false, false] },
              {
                text: message === "sale" ? baht(total(data.detail_print.order_list_data, "yard_qty")) : baht(total(data.detail_print.order_list, "yard_qty")),
                bold: true,
                alignment: "center",
                border: [false, true, false, false],
                noWrap: true,
              },
              { text: "หลา", alignment: "left", bold: true, border: [false, true, false, false], padding: [10, 0, 0, 0] },
            ],
            [
              {
                text: data.detail_print.remark ? "หมายเหตุ: " + data.detail_print.remark : "หมายเหตุ: -",
                colSpan: 3,
                rowSpan: 4,
                border: [false, true, false, false],
              },
              "",
              "",
              { text: "รวม", alignment: "left", border: [false, true, false, false] },
              { text: baht(data.detail_print.total_price), colSpan: 2, alignment: "right", border: [false, true, false, false] },
              "",
            ],
            ["", "", "", { text: "มัดจำ", alignment: "left", border: [false, false, false, false] }, { text: baht(data.detail_print.deposit), colSpan: 2, alignment: "right", border: [false, false, false, false] }, ""],

            ["", "", "", { text: "ส่วนลด", alignment: "left", border: [false, false, false, false] }, { text: baht(data.detail_print.discount), colSpan: 2, alignment: "right", border: [false, false, false, false] }, ""],
            ["", "", "", { text: "รวมทั้งสิ้น", alignment: "right", noWrap: true, fontSize: 11, bold: true, border: [false, true, false, false] }, { text: data.detail_print.status_bill === 3 ? "0.00" : baht(data.detail_print.total_price_final - data.detail_print.deposit), colSpan: 2, fontSize: 11, bold: true, alignment: "right", border: [false, true, false, false] }, ""],
          ],
        },
        margin: [0, 0, 0, 170],
      },
      { text: " " },
      {
        text: "ชำระแล้วรบกวนแจ้ง",
        absolutePosition: { x: 15, y: 660 },
      },
      {
        text: "LINE : @RUF1270S",
        absolutePosition: { x: 15, y: 675 },
      },
      {
        text: "TEL/Line ID: 0864800270",
        absolutePosition: { x: 15, y: 690 },
      },
      { text: " " },
      {
        table: {
          headerRows: 1,
          widths: ["*", "*", "*"],
          body: [
            [
              { stack: [" ", ".........................................................................................", "ผู้อนุมัติสินค้า", "วันที่..........................................................."], alignment: "center", border: [false, true, false, false] },
              { stack: [" ", ".........................................................................................", "ผู้ส่งสินค้า", "วันที่..........................................................."], alignment: "center", border: [false, true, false, false] },
              { stack: [" ", ".........................................................................................", "ผู้รับสินค้า", "วันที่..........................................................."], alignment: "center", border: [false, true, false, false] },
            ],
          ],
        },
        absolutePosition: { x: 25, y: 710 },
      },
    ],
    defaultStyle: { font: "FCIconic", fontSize: 10, lineHeight: 1 },
    pageSize: "A4",
    pageMargins: [12, 100, 12, 25],
  };
  print(docDefinition, "ใบเสนอราคา/ใบส่งสินค้าชั่วคราว " + new Date().toISOString() + ".pdf");
}
// ใบกำกับภาษี
function report_3(data, message) {
  let address = "";

  if (data.member.sub_district !== "") {
    address = data.member.address + " ต." + data.member.sub_district + " อ." + data.member.district + " จ." + data.member.province + " " + data.member.post_code;
  } else if (data.member.district !== "" && data.member.province !== "") {
    address = data.member.address + " อ." + data.member.district + " จ." + data.member.province + " " + data.member.post_code;
  } else {
    address = "-";
  }

  let array_product = data.order_list;
  let array = [];
  for (let i = 0; i < array_product.length; i++) {
    array.push([
      { text: i + 1, alignment: "center", border: [false, false, false, false] },
      { text: array_product[i].qty, alignment: "center", border: [false, false, false, false] },
      { text: array_product[i].product_main.product_main_data.product_name, alignment: "left", border: [false, false, false, false] },
      { text: array_product[i].detail_yard_list.toString().replaceAll(",", ", "), alignment: "left", border: [false, false, false, false] },
      {
        text: baht(array_product[i].price_by_item),
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: baht(array_product[i].yard_qty),
        alignment: "right",
        border: [false, false, false, false],
      },
      {
        text: baht(array_product[i].total_price),
        alignment: "right",
        border: [false, false, false, false],
      },
    ]);
  }

  var docDefinition = {
    content: [
      {
        columns: [
          { width: 200, text: "", fontSize: 18, bold: true, color: "#4E6FFB" },
          { width: "*", text: "ใบกำกับภาษี", alignment: "right", fontSize: 18, bold: true },
        ],
      },
      { text: " " },
      {
        columns: [
          {
            stack: [
              { text: "393 ถ.ภูผาภักดี ต.บางนาค อ.เมือง จ.นราธิวาส 96000", fontSize: 11, bold: true },
              { text: "โทร: 086-4800270", fontSize: 11, bold: true },
            ],
            alignment: "left",
          },
          {
            stack: [
              { text: data.data_number.ab_number ? "เลขที่: " + data.data_number.ab_number : "เลขที่: -", fontSize: 11, bold: true },
              {
                text: "วัน/เดือน/ปี: " + ("0" + new Date().getDate()).slice(-2) + "/" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "/" + (new Date().getFullYear() + 543),
                fontSize: 11,
                bold: true,
              },
              { text: "เลขที่ผู้เสียภาษี: 1909800888896", fontSize: 11, bold: true },
            ],
            alignment: "right",
          },
        ],
      },
      { text: " " },
      {
        table: {
          headerRows: 1,
          widths: ["13%", "20.33%", "13%", "20%", "14.33%", "19.32%"],
          body: [
            [
              {
                border: [false, true, false, false],
                text: "รหัสลูกค้า:",
              },
              {
                border: [false, true, false, false],
                text: data.member.user_code ? data.member.user_code : "-",
              },
              {
                border: [false, true, false, false],
                text: "เลขที่ผู้เสียภาษี:",
              },
              {
                border: [false, true, false, false],
                text: data.member.tax_invoice ? data.member.tax_invoice : "-",
              },
              {
                border: [false, true, false, false],
                text: "ครบกำหนดชำระ:",
              },
              {
                border: [false, true, false, false],
                text: data.detail_print.due_date ? data.detail_print.due_date : "-",
              },
            ],
            [
              {
                border: [false, false, false, false],
                text: "ชื่อลูกค้า:",
              },
              {
                border: [false, false, false, false],
                text: data.member.full_name ? data.member.full_name : "-",
              },
              {
                border: [false, false, false, false],
                text: "",
              },
              {
                border: [false, false, false, false],
                text: "",
              },
              {
                border: [false, false, false, false],
                text: "เบอร์โทร:",
              },
              {
                border: [false, false, false, false],
                text: data.member.phone ? data.member.phone : "-",
              },
            ],
            [
              {
                border: [false, false, false, true],
                text: "ที่อยู่:",
              },
              {
                border: [false, false, false, true],
                text: address,
                colSpan: 3,
              },
              { text: " " },
              { text: " " },
              {
                border: [false, false, false, true],
                text: " ",
              },
              {
                border: [false, false, false, true],
                text: " ",
              },
            ],
          ],
        },
      },
      { text: " " },
      {
        table: {
          headerRows: 1,
          widths: ["5%", "8%", "22%", "19%", "14%", "14%", "17%"],
          body: [
            [
              { text: "No.", bold: true, alignment: "center", border: [false, true, false, true] },
              { text: "QTY.ไม้", bold: true, alignment: "center", border: [false, true, false, true] },
              { text: "ชื่อสินค้า/บริการ", bold: true, alignment: "left", border: [false, true, false, true] },
              { text: "รายละเอียด", bold: true, alignment: "left", border: [false, true, false, true] },
              { text: "ราคา", bold: true, alignment: "right", border: [false, true, false, true] },
              { text: "QTY.หลา", bold: true, alignment: "right", border: [false, true, false, true] },
              { text: "จำนวนเงิน", bold: true, alignment: "right", border: [false, true, false, true] },
            ],
            ...array,
            [
              { text: "รวม", alignment: "right", bold: true, border: [false, true, false, false] },
              {
                text: message === "Transaction" ? total(data.detail_print.order_list_data, "qty") : total(data.detail_print.order_list, "qty"),
                alignment: "center",
                border: [false, true, false, false],
                bold: true,
              },
              { text: "ไม้", alignment: "left", border: [false, true, false, false], bold: true },
              { text: "", alignment: "center", border: [false, true, false, false] },
              { text: "รวม", alignment: "right", border: [false, true, false, false], bold: true },
              {
                text: message === "Transaction" ? baht(total(data.detail_print.order_list_data, "yard_qty")) : baht(total(data.detail_print.order_list, "yard_qty")),
                alignment: "center",
                border: [false, true, false, false],
                bold: true,
              },
              { text: "หลา", alignment: "left", border: [false, true, false, false], bold: true },
            ],
            [{ text: data.detail_print.remark ? "หมายเหตุ: " + data.detail_print.remark : "หมายเหตุ: -", colSpan: 5, rowSpan: 6, border: [false, true, false, false] }, "", "", "", "", { text: "รวม", alignment: "left", border: [false, true, false, false] }, { text: baht(data.detail_print.total_price_final), alignment: "right", border: [false, true, false, false] }],
            ["", "", "", "", "", { text: "หักส่วนลด", border: [false, false, false, false] }, { text: data.detail_print.discount ? baht(data.detail_print.discount) : "0.00", alignment: "right", border: [false, false, false, false] }],
            ["", "", "", "", "", { text: "ค่าจัดส่ง", border: [false, false, false, false] }, { text: data.detail_print.delivery_price ? baht(data.detail_print.delivery_price) : "0.00", alignment: "right", border: [false, false, false, false] }],
            ["", "", "", "", "", { text: "ค่ามัดจำ", border: [false, false, false, false] }, { text: data.detail_print.deposit ? baht(data.detail_print.deposit) : "0.00", alignment: "right", border: [false, false, false, false] }],
            ["", "", "", "", "", { text: "ภาษี(7%)", border: [false, false, false, false] }, { text: data.detail_print.vat ? baht(data.detail_print.vat) : "0.00", alignment: "right", border: [false, false, false, false] }],
            ["", "", "", "", "", { text: "รวมทั้งสิ้น", border: [false, true, false, false], fontSize: 11, bold: true }, { text: data.detail_print.total_price_final ? baht(data.detail_print.total_price_final) : "0.00", alignment: "right", fontSize: 11, bold: true, border: [false, true, false, false] }],
          ],
        },
        margin: [0, 0, 0, 70],
      },
      { text: " " },
      {
        text: "ชำระแล้วรบกวนแจ้ง",
        absolutePosition: { x: 15, y: 660 },
      },
      {
        text: "LINE : @RUF1270S",
        absolutePosition: { x: 15, y: 675 },
      },
      {
        text: "TEL/Line ID: 0864800270",
        absolutePosition: { x: 15, y: 690 },
      },
      { text: " " },
      {
        table: {
          headerRows: 1,
          widths: ["*", "*"],
          body: [
            [
              {
                stack: [
                  { text: "ชำระเงินโดย:", border: [false, false, false, false] },
                  { text: data.detail_print.payment_type_layer_1 === 1 ? "x เงินสด" : "▢ เงินสด", border: [false, false, false, false] },
                  { text: data.detail_print.payment_type_layer_1 === 3 ? "x เงินโอน" : "▢ เงินโอน", border: [false, false, false, false] },
                  {
                    text: data.detail_print.payment_type_layer_1 === 2 ? "x อื่นๆ เงินเชื่อ" : data.detail_print.payment_type_layer_1 === 4 ? "x อื่นๆ แบ่งงวด" : data.detail_print.payment_type_layer_1 === 5 ? "x อื่นๆ เงินสด/โอน" : "▢ อื่นๆ ______",
                    border: [false, false, false, false],
                  },
                ],
                border: [false, true, false, false],
              },
              {
                stack: [" ", "....................................................................", "ผู้รับมอบอำนาจ", "วันที่............................................................"],
                alignment: "center",
                border: [false, true, false, false],
              },
            ],
          ],
        },
        absolutePosition: { x: 25, y: 710 },
      },
      {
        width: data.detail_print.status_bill === 3 ? 150 : data.detail_print.status_bill === 6 ? 150 : 0,
        svg: data.detail_print.status_bill === 3 ? '<svg width="150" height="150"><text transform="translate(100, 100) rotate(-45)" font-weight="bold" font-size="50" fill="none" stroke="black" stroke-width="1" text-anchor="middle" alignment-baseline="middle">PAID</text> </svg>' : data.detail_print.status_bill === 6 ? '<svg width="150" height="150"><text transform="translate(100, 100) rotate(-45)" font-weight="bold" font-size="30" fill="none" stroke="black" stroke-width="1" text-anchor="middle" alignment-baseline="middle">CANCEL</text> </svg>' : '<svg width="150" height="150"><text transform="translate(100, 100) rotate(-45)" font-weight="bold" font-size="30" fill="none" stroke="black" stroke-width="1" text-anchor="middle" alignment-baseline="middle"></text> </svg>',
        absolutePosition: { x: 180, y: 250 },
      },
    ],
    defaultStyle: { font: "FCIconic", fontSize: 10, lineHeight: 1.2 },
    pageSize: "A4",
    pageMargins: [25, 100, 25, 25],
  };
  print(docDefinition, "ใบกำกับภาษี " + new Date().toISOString() + ".pdf");
}
// ใบแจ้งหนี้
function report_4(data) {
  let array_product = data.order_main_list;
  let array = [];
  for (let i = 0; i < array_product.length; i++) {
    array.push([
      { text: i + 1, alignment: "center", border: [false, false, false, false] },
      {
        text: array_product[i].datetime_create,
        alignment: "left",
        border: [false, false, false, false],
      },
      { text: array_product[i].order_main_number, alignment: "center", border: [false, false, false, false] },
      {
        text: baht(array_product[i].total_balance),
        alignment: "right",
        border: [false, false, false, false],
      },
    ]);
  }

  var docDefinition = {
    content: [
      {
        columns: [
          { width: 200, text: "", fontSize: 18, bold: true, color: "#4E6FFB" },
          { width: "*", text: "ใบแจ้งหนี้", alignment: "right", fontSize: 18, bold: true },
        ],
      },
      {
        columns: [
          {
            stack: ["ชื่อลูกค้า: " + data.cutomer_name],
            alignment: "left",
            fontSize: 11,
            bold: true,
          },
          {
            stack: ["เลขที่: " + data.invoice_number, "วัน/เดือน/ปี: " + data.datetime_create],
            alignment: "right",
            fontSize: 11,
            bold: true,
          },
        ],
      },
      {
        table: {
          headerRows: 1,
          widths: ["7%", "*", "15%", "15%"],
          body: [
            [
              { text: "No.", bold: true, alignment: "center", border: [false, true, false, true] },
              { text: "วันที่", bold: true, alignment: "lett", border: [false, true, false, true] },
              { text: "เลขเอกสาร", bold: true, alignment: "center", border: [false, true, false, true] },
              { text: "จำนวนเงิน", bold: true, alignment: "right", border: [false, true, false, true] },
            ],

            ...array,
            [{ text: "", colSpan: 2, border: [false, true, false, false] }, "", { text: "รวมทั้งสิ้น", fontSize: 11, bold: true, alignment: "right", border: [false, true, false, false] }, { text: baht(total(data.order_main_list, "total_balance")), alignment: "right", fontSize: 11, bold: true, border: [false, true, false, false] }],
          ],
        },
        margin: [0, 0, 0, 80],
      },
      { text: " " },
      {
        text: "หมายเหตุ: ชำระแล้วรบกวนแจ้ง",
        absolutePosition: { x: 15, y: 660 },
      },
      {
        text: "LINE : @RUF1270S",
        absolutePosition: { x: 15, y: 675 },
      },
      {
        text: "TEL/Line ID: 0864800270",
        absolutePosition: { x: 15, y: 690 },
      },
      {
        table: {
          headerRows: 1,
          widths: ["*", "*", "*"],
          body: [
            [
              { stack: [" ", ".........................................................................................", "ผู้อนุมัติ", "วันที่..........................................................."], alignment: "center", border: [false, true, false, false] },
              { stack: [" ", ".........................................................................................", "ผู้วางบิล", "วันที่..........................................................."], alignment: "center", border: [false, true, false, false] },
              { stack: [" ", ".........................................................................................", "ผู้รับบิล", "วันที่..........................................................."], alignment: "center", border: [false, true, false, false] },
            ],
          ],
        },
        absolutePosition: { x: 15, y: 710 },
      },
      {
        width: data.status_bill === 2 ? 150 : 0,
        svg: data.status_bill === 2 ? '<svg width="150" height="150"><text transform="translate(100, 100) rotate(-45)" font-weight="bold" font-size="50" fill="none" stroke="black" stroke-width="1" text-anchor="middle" alignment-baseline="middle">PAID</text> </svg>' : '<svg width="150" height="150"><text transform="translate(100, 100) rotate(-45)" font-weight="bold" font-size="30" fill="none" stroke="black" stroke-width="1" text-anchor="middle" alignment-baseline="middle"></text> </svg>',
        absolutePosition: { x: 180, y: 120 },
      },
    ],
    defaultStyle: { font: "FCIconic", fontSize: 10, lineHeight: 1.5 },
    pageSize: "A4",
    pageMargins: [25, 100, 25, 25],
  };
  print(docDefinition, "ใบแจ้งหนี้ " + new Date().toISOString() + ".pdf");
}
// ใบส่งของสายส่ง
function report_5(data, message) {
  let array_product = data.data;
  var array = [];
  for (let i = 0; i < array_product.length; i++) {
    array.push([{ text: i + 1, alignment: "center" }, { text: format_date_notime(array_product[i].datetime_create), alignment: "center" }, { text: array_product[i].ab_number, alignment: "left" }, { text: array_product[i].cutomer_name, alignment: "left" }, { text: array_product[i].cutomer_phone, alignment: "left" }, { text: number(array_product[i].qty), alignment: "center" }, { text: baht(array_product[i].total_price_final), alignment: "right" }, { text: "" }, { text: "" }]);
  }

  var docDefinition = {
    content: [
      {
        columns: [
          { width: 200, text: "เลขที่: " + data.relation_status_delivery.delivery_number, fontSize: 18, bold: true },
          { width: "*", text: "ใบรายการส่ง", alignment: "right", fontSize: 18, bold: true },
        ],
      },
      {
        columns: [
          {
            stack: ["วัน/เดือน/ปี: " + format_date(data.relation_status_delivery.datetime_create)],
            alignment: "right",
            fontSize: 11,
            bold: true,
          },
        ],
      },
      " ",
      {
        table: {
          headerRows: 1,
          widths: ["4%", "9%", "13.70%", "26.74%", "11.33%", "7.50%", "*", "8%", "8%"],
          body: [
            [
              { text: "No.", bold: true, alignment: "center" },
              { text: "วันที่", bold: true, alignment: "center" },
              { text: "เลขเอกสาร", bold: true, alignment: "left" },
              { text: "ชื่อลูกค้า", bold: true, alignment: "left" },
              { text: "เบอร์โทร", bold: true, alignment: "left" },
              { text: "จำนวนไม้", bold: true, alignment: "center" },
              { text: "จำนวนเงิน", bold: true, alignment: "right" },
              { text: "วิธีรับเงิน", bold: true, alignment: "center" },
              { text: "หมายเหตุ", bold: true, alignment: "center" },
            ],
            ...array,
            [{ text: data.relation_status_delivery.remark_delivery ? "หมายเหตุ: " + data.relation_status_delivery.remark_delivery : "หมายเหตุ: -", colSpan: 9 }],
          ],
        },
        margin: [0, 0, 0, 80],
      },
      {
        table: {
          headerRows: 1,
          widths: ["*", "*"],
          body: [
            [
              { stack: [" ", ".........................................................................................", "ผู้ออกบิล", "วันที่..........................................................."], alignment: "center", border: [false, true, false, false] },
              { stack: [" ", ".........................................................................................", "สายส่ง", "วันที่..........................................................."], alignment: "center", border: [false, true, false, false] },
            ],
          ],
        },
        absolutePosition: { x: 15, y: 710 },
      },
    ],
    defaultStyle: { font: "FCIconic", fontSize: 9, lineHeight: 1 },
    pageSize: "A4",
    pageMargins: [15, 15, 15, 15],
  };
  print(docDefinition, "ใบส่งของสายส่ง " + new Date().toISOString() + ".pdf");
}
//ใบกำกับภาษีอย่างย่อ/ใบเสร็จรับเงิน
function report_6(data, general) {
  let array_product = data.order_list;
  var small_product = [];
  for (let i = 0; i < array_product.length; i++) {
    small_product.push({
      columns: [
        {
          width: "10%",
          text: "X" + array_product[i].qty,
        },
        {
          width: "70%",
          text: array_product[i].product_main.product_main_data.product_name,
        },
        {
          width: "20%",
          text: baht(array_product[i].total_price),
          alignment: "right",
        },
      ],
    });
  }

  var docDefinition = {
    pageSize: {
      width: 302,
      height: "auto",
    },

    content: [
      {
        text: "",
        fontSize: 22,
        alignment: "center",
        bold: true,
        color: "#4E6FFB",
      },
      {
        text: "ใบกำกับภาษีอย่างย่อ/ใบเสร็จรับเงิน",
        fontSize: 13,
        alignment: "center",
      },
      {
        text: data.data_number.ab_number || data.data_number.est_number,
        alignment: "center",
        fontSize: 12,
        bold: true,
      },
      {
        text: "......................................................................................................................................",
      },
      {
        columns: [
          {
            width: "20%",
            text: "พนักงาน:",
          },
          {
            width: "80%",
            text: data.machine_profile.full_name ? data.machine_profile.full_name : data.machine_profile ? data.machine_profile : "-",
            alignment: "right",
          },
        ],
      },
      {
        columns: [
          {
            width: "20%",
            text: "POS:",
          },
          {
            width: "80%",
            text: data.detail_print.machine_number ? data.detail_print.machine_number : data.detail_print.admin_data.user_code ? data.detail_print.admin_data.user_code : "-",
            alignment: "right",
          },
        ],
      },
      {
        columns: [
          {
            width: "20%",
            text: "ลูกค้า:",
          },
          {
            width: "80%",
            text: data.member.full_name ? data.member.full_name : "-",
            alignment: "right",
          },
        ],
      },
      {
        text: "......................................................................................................................................",
      },
      ...small_product,
      {
        text: "......................................................................................................................................",
      },
      {
        columns: [
          {
            width: "25%",
            text: "ยอดรวม:",
          },
          {
            width: "75%",
            text: data.detail_print.total_price ? baht(data.detail_print.total_price) : "0.00",
            alignment: "right",
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "ส่วนลด:",
          },
          {
            width: "75%",
            text: data.detail_print.discount ? baht(data.detail_print.discount) : "0.00",
            alignment: "right",
          },
        ],
      },
      {
        columns: [
          {
            width: "30%",
            text: "ค่าบริการจัดส่ง:",
          },
          {
            width: "70%",
            text: data.detail_print.delivery_price ? baht(data.detail_print.delivery_price) : "0.00",
            alignment: "right",
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: "ยอดรวมสุทธิ:",
          },
          {
            width: "75%",
            text: data.detail_print.total_price_final ? baht(data.detail_print.total_price_final) : "0.00",
            alignment: "right",
          },
        ],
      },
      {
        text: "......................................................................................................................................",
      },

      {
        columns: [
          {
            width: "25%",
            text: "ชำระด้วย:",
          },
          {
            width: "75%",
            text: data.detail_print.payment_type_layer_1 === 0 ? "รอชำระ" : data.detail_print.payment_type_layer_1 === 1 ? "เงินสด" : data.detail_print.payment_type_layer_1 === 2 ? "เงินเชื่อ" : data.detail_print.payment_type_layer_1 === 3 ? "เงินโอน" : data.detail_print.payment_type_layer_1 === 4 ? "เป็นงวด" : data.detail_print.payment_type_layer_1 === 5 ? "เงินสด / โอน" : "-",
            alignment: "right",
          },
        ],
      },
      {
        columns: [
          {
            width: "35%",
            text: "รับยอดเงินทั้งหมด:",
          },
          {
            width: "65%",
            text: general === "Transaction" ? (data.detail_print.total_price_final ? baht(data.detail_print.total_price_final) : "0.00") : data.detail_print.payment_detail.total_pay ? baht(data.detail_print.payment_detail.total_pay) : "0.00",
            alignment: "right",
          },
        ],
      },
      {
        columns: [
          {
            width: "25%",
            text: general === "Transaction" || data.detail_print.payment_type_layer_1 === 4 ? null : "เงินทอน",
          },
          {
            width: "75%",
            text: general === "Transaction" || data.detail_print.payment_type_layer_1 === 4 ? null : baht(general) ? baht(general) : "0.00",
            alignment: "right",
          },
        ],
      },
      {
        text: "......................................................................................................................................",
      },
      {
        text: "วันที่ " + ("0" + new Date().getDate()).slice(-2) + "/" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "/" + (new Date().getFullYear() + 543) + " , " + new Date().getHours() + ":" + new Date().getMinutes() + ":" + new Date().getSeconds(),
        alignment: "center",
      },
      {
        text: "ขอขอบคุณที่อุดหนุน",
        alignment: "center",
      },
    ],
    defaultStyle: { font: "FCIconic", fontSize: 11, lineHeight: 1.5 },
    pageMargins: [15, 15, 15, 15],
  };

  print(docDefinition, "ใบกำกับภาษีอย่างย่อ/ใบเสร็จรับเงิน " + new Date().toISOString() + ".pdf");
}
function report_expenses(data) {
  var array = [];
  for (let item of data) {
    array.push({ text: " " });
    array.push({
      columns: [
        { width: "auto", text: "วันที่ : ", bold: true },
        { width: "auto", text: format_date(item.datetime_create), margin: [4, 0, 16, 0] },
        { width: "auto", text: "เลขที่เอกสาร : ", bold: true },
        { width: "auto", text: item.expense_main_number, margin: [4, 0, 16, 0] },
        { width: "auto", text: "เลขที่เอกสารอ้างอิง : ", bold: true },
        { width: "auto", text: item.ref_receipt_code, margin: [4, 0, 16, 0] },
        { width: "auto", text: "ประเภท : ", bold: true },
        {
          width: "auto",
          text: item.type === 1 ? "ค่าใช้จ่าย" : item.type === 2 ? "ปรับสต๊อก" : item.type === 3 ? "ส่วนลด" : "",
          margin: [4, 0, 16, 0],
        },
      ],
    });
    let table = [
      [
        { text: "ลำดับ", alignment: "center" },
        { text: "รายละเอียด", alignment: "center" },
        { text: "จำนวน/หน่วย", alignment: "center" },
        { text: "ราคา/หน่วย", alignment: "center" },
        { text: "รวมสุทธิ", alignment: "center" },
      ],
    ];
    let ex_index = 0;
    for (let ex of item.expense_list) {
      table.push([
        { text: ex_index + 1, alignment: "center" },
        { text: ex.detail, alignment: "center" },
        { text: ex.unit, alignment: "center" },
        { text: baht(ex.price_by_unit), alignment: "center" },
        { text: baht(ex.total_price), alignment: "center" },
      ]);
      ex_index += 1;
    }
    table.push([
      { text: "รวมสุทธิ", colSpan: 4, alignment: "left" },
      { text: "", alignment: "center" },
      { text: "", alignment: "center" },
      { text: "", alignment: "center" },
      { text: baht(item.total), alignment: "center" },
    ]);
    array.push({
      table: {
        headerRows: 1,
        widths: ["20%", "20%", "20%", "20%", "20%"],
        body: [...table],
      },
    });
  }
  var docDefinition = {
    content: [
      {
        columns: [
          { width: 200, text: "", fontSize: 18, bold: true, color: "#4E6FFB" },
          { width: "*", text: "ค่าใช้จ่าย", alignment: "right", fontSize: 18, bold: true },
        ],
      },
      { text: " " },
      ...array,
      { text: " " },
      {
        columns: [
          { text: "รวมทั้งสิ้น", bold: true, fontSize: 10 },
          { text: baht(total(data, "total")) + " บาท", bold: true, fontSize: 10, alignment: "right" },
        ],
      },
    ],
    defaultStyle: { font: "FCIconic", fontSize: 7, lineHeight: 1 },
    pageSize: "A4",
    pageMargins: [15, 15, 15, 15],
  };
  print(docDefinition, "ค่าใช้จ่าย " + new Date().toISOString() + ".pdf");
}

// คืนสินค้าตามรายการ
function report_refund_byid(data) {
  let array_product = data.refund_list;
  let array = [];
  for (let i = 0; i < array_product.length; i++) {
    array.push([{ text: array_product[i].invoice_number, alignment: "left", border: [false, false, false, false], colSpan: 4, fillColor: "#dfdfdf" }, "", "", ""]);
    for (let j = 0; j < array_product[i].order_list.length; j++) {
      array.push([
        { text: array_product[i].order_list[j].product_name, alignment: "left", border: [false, false, false, false] },
        { text: array_product[i].order_list[j].yard_qty, alignment: "left", border: [false, false, false, false] },
        { text: baht(array_product[i].order_list[j].price_by_item), alignment: "right", border: [false, false, false, false] },
        { text: baht(array_product[i].order_list[j].price_by_item * array_product[i].order_list[j].yard_qty), alignment: "right", border: [false, false, false, false] },
      ]);
    }
  }

  var docDefinition = {
    content: [
      {
        columns: [
          { width: 200, text: "", fontSize: 18, bold: true, color: "#4E6FFB" },
          { width: "*", text: "REFUND RECEIPT", alignment: "right", fontSize: 18, bold: true },
        ],
      },
      { text: " " },
      {
        columns: [
          {
            stack: [{ text: "REFUND TO " + data.refund_list[0].member_name, fontSize: 14, bold: true }],
            alignment: "left",
          },
          {
            stack: [
              { text: "REFUND: " + data.rc_number, fontSize: 11, bold: true },
              {
                text: "REFUND DATE: " + format_date(data.pick_up_datetime),
                fontSize: 11,
                bold: true,
              },
            ],
            alignment: "right",
          },
        ],
      },
      { text: " " },
      {
        table: {
          headerRows: 1,
          widths: ["40%", "20%", "20%", "20%"],
          body: [
            [
              { text: "DESCRIPTION", bold: true, alignment: "left", border: [false, true, false, true] },
              { text: "QTY", bold: true, alignment: "left", border: [false, true, false, true] },
              { text: "RATE", bold: true, alignment: "right", border: [false, true, false, true] },
              { text: "AMOUNT", bold: true, alignment: "right", border: [false, true, false, true] },
            ],
            ...array,
            [{ text: "", colSpan: 2, rowSpan: 3, border: [false, true, false, false] }, "", { text: "SUBTOTAl", alignment: "left", border: [false, true, false, false] }, { text: baht(total(array_product, "net_total")), alignment: "right", border: [false, true, false, false] }],
            [{ text: "", colSpan: 2, border: [false, false, false, false] }, "", { text: "TAX", alignment: "left", border: [false, false, false, true] }, { text: "0.00", alignment: "right", border: [false, false, false, true] }],
            [{ text: "", colSpan: 2, border: [false, false, false, false] }, "", { text: "TOTAL", alignment: "left", bold: true, border: [false, false, false, false] }, { text: baht(total(array_product, "net_total")), bold: true, alignment: "right", border: [false, false, false, false] }],
          ],
        },
        margin: [0, 0, 0, 173],
      },
    ],
    defaultStyle: { font: "FCIconic", fontSize: 10, lineHeight: 1 },
    pageSize: "A4",
    pageMargins: [12, 100, 12, 25],
  };
  print(docDefinition, "REFUND RECEIPT " + new Date().toISOString() + ".pdf");
}
function report_reciept(data) {
  var user_type = localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")).user_type : "";
  var array = [];
  if (user_type !== "owner") {
    for (let item of data) {
      array.push({ text: " " });
      array.push({
        columns: [
          { width: "auto", text: "วันที่รับ : ", bold: true },
          { width: "auto", text: format_date(item.receive_date), margin: [4, 0, 16, 0] },
          { width: "auto", text: "รหัสใบรับสินค้า : ", bold: true },
          { width: "auto", text: item.bill_receipt_code, margin: [4, 0, 16, 0] },
          { width: "auto", text: "ผู้จำหน่าย : ", bold: true },
          { width: "auto", text: item.user_approve, margin: [4, 0, 16, 0] },
          { width: "auto", text: "เอกสารอ้างอิง : ", bold: true },
          { width: "auto", text: item.ref_receipt_code, margin: [4, 0, 16, 0] },
          { width: "auto", text: "โกดัง : ", bold: true },
          { width: "auto", text: item.goods_receipt_list[0].warehouse, margin: [4, 0, 16, 0] },
        ],
      });
      let table = [
        [
          { text: "ลำดับ", alignment: "center" },
          { text: "รหัสสินค้า", alignment: "center" },
          { text: "ชื่อสินค้า", alignment: "center" },
          { text: "จำนวนหลา", alignment: "center" },
          { text: "ไม้", alignment: "center" },
        ],
      ];
      let ex_index = 0;
      for (let ex of item.goods_receipt_list) {
        table.push([
          { text: ex_index + 1, alignment: "center" },
          { text: ex.product_code, alignment: "center" },
          { text: ex.product_name, alignment: "left" },
          { text: ex.yard_qty, alignment: "center" },
          { text: ex.qty, alignment: "center" },
        ]);
        ex_index += 1;
      }
      array.push({
        table: {
          headerRows: 1,
          widths: ["5%", "12%", "59%", "12%", "12%"],
          body: [...table],
        },
      });
    }
    var docDefinition = {
      content: [
        {
          columns: [
            { width: 200, text: "", fontSize: 18, bold: true, color: "#4E6FFB" },
            { width: "*", text: "รายงานรับเข้า", alignment: "right", fontSize: 18, bold: true },
          ],
        },
        { text: " " },
        ...array,
        { text: " " },
      ],
      defaultStyle: { font: "FCIconic", fontSize: 7, lineHeight: 1 },
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
    };
    print(docDefinition, "รายงานคืนสินค้า " + new Date().toISOString() + ".pdf");
  } else {
    for (let item of data) {
      array.push({ text: " " });
      array.push({
        columns: [
          { width: "auto", text: "วันที่รับ : ", bold: true },
          { width: "auto", text: format_date(item.receive_date), margin: [4, 0, 16, 0] },
          { width: "auto", text: "รหัสใบรับสินค้า : ", bold: true },
          { width: "auto", text: item.bill_receipt_code, margin: [4, 0, 16, 0] },
          { width: "auto", text: "ผู้จำหน่าย : ", bold: true },
          { width: "auto", text: item.user_approve, margin: [4, 0, 16, 0] },
          { width: "auto", text: "เอกสารอ้างอิง : ", bold: true },
          { width: "auto", text: item.ref_receipt_code, margin: [4, 0, 16, 0] },
          { width: "auto", text: "โกดัง : ", bold: true },
          { width: "auto", text: item.goods_receipt_list[0].warehouse, margin: [4, 0, 16, 0] },
        ],
      });
      let table = [
        [
          { text: "ลำดับ", alignment: "center" },
          { text: "รหัสสินค้า", alignment: "center" },
          { text: "ชื่อสินค้า", alignment: "center" },
          { text: "จำนวนหลา", alignment: "center" },
          { text: "ไม้", alignment: "center" },
          { text: "ราคาที่รับมา", alignment: "center" },
          { text: "ยอดรวมสุทธิ", alignment: "center" },
        ],
      ];
      let ex_index = 0;
      for (let ex of item.goods_receipt_list) {
        table.push([
          { text: ex_index + 1, alignment: "center" },
          { text: ex.product_code, alignment: "center" },
          { text: ex.product_name, alignment: "left" },
          { text: ex.yard_qty, alignment: "center" },
          { text: ex.qty, alignment: "center" },
          { text: baht(ex.price_by_item), alignment: "right" },
          { text: baht(ex.total_price_by_item), alignment: "right" },
        ]);
        ex_index += 1;
      }
      table.push([{ text: "รวมทั้งสิ้น", alignment: "right", colSpan: 6 }, "", "", "", "", "", { text: data ? baht(total(item.goods_receipt_list, "total_price_by_item")) : "0.00", alignment: "right" }]);
      array.push({
        table: {
          headerRows: 1,
          widths: ["5%", "12%", "25%", "12%", "12%", "17%", "17%"],
          body: [...table],
        },
      });
    }
    docDefinition = {
      content: [
        {
          columns: [
            { width: 200, text: "", fontSize: 18, bold: true, color: "#4E6FFB" },
            { width: "*", text: "รายงานรับเข้า", alignment: "right", fontSize: 18, bold: true },
          ],
        },
        { text: " " },
        ...array,
        { text: " " },
        {
          columns: [
            { text: "รวมทั้งสิ้น", bold: true, fontSize: 10 },
            { text: baht(total(data, "total_price_by_item")) + " บาท", bold: true, fontSize: 10, alignment: "right" },
          ],
        },
      ],
      defaultStyle: { font: "FCIconic", fontSize: 7, lineHeight: 1 },
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
    };
    print(docDefinition, "รายงานคืนสินค้า " + new Date().toISOString() + ".pdf");
  }
}
function report_refund(data) {
  var array = [];
  for (let item of data) {
    array.push({ text: " " });
    array.push({
      columns: [
        { width: "auto", text: "วันที่สร้างเอกสาร : ", bold: true },
        { width: "auto", text: format_date(item.datetime_create), margin: [4, 0, 16, 0] },
        { width: "auto", text: "เลขที่เอกสาร : ", bold: true },
        { width: "auto", text: item.rc_number, margin: [4, 0, 16, 0] },
        { width: "auto", text: "ชื่อลูกค้า : ", bold: true },
        { width: "auto", text: item.member_name, margin: [4, 0, 16, 0] },
        { width: "auto", text: "สถานะ : ", bold: true },
        {
          width: "auto",
          text: item.status_return_store === 1 ? "คืนเข้าโกดัง" : item.status_return_store === 2 ? "ไม่คืนเข้าโกดัง" : "",
          margin: [4, 0, 16, 0],
        },
      ],
    });
    let table = [
      [
        { text: "เลขที่ใบขายสินค้า", alignment: "center" },
        { text: "ชื่อสิค้า", alignment: "center" },
        { text: "จำนวนหลาที่คืน", alignment: "center" },
        { text: "จำนวนไม้ที่คืน", alignment: "center" },
        { text: "รวมหลา", alignment: "center" },
        { text: "ราคาต่อหน่วย", alignment: "center" },
        { text: "รวมสุทธิ", alignment: "center" },
      ],
    ];
    for (let ex of item.refund_main) {
      table.push([
        { text: ex.invoice_number, alignment: "left", colSpan: 6 },
        { text: "", alignment: "center" },
        { text: "", alignment: "center" },
        { text: "", alignment: "center" },
        { text: "", alignment: "center" },
        { text: "", alignment: "center" },
        { text: baht(ex.net_total), alignment: "center" },
      ]);
      for (let refund of ex.refund_list) {
        table.push([
          { text: "", alignment: "center" },
          { text: refund.product_name, alignment: "left" },
          { text: refund.yard_qty, alignment: "center" },
          { text: refund.wood_qty, alignment: "center" },
          { text: refund.total_price, alignment: "center" },
          { text: baht(refund.price_by_item), alignment: "center" },
          { text: baht(refund.price_by_item * refund.yard_qty), alignment: "center" },
        ]);
      }
    }
    table.push([{ text: "หมายเหตุ : " + item.remark, alignment: "left", colSpan: 3 }, "", "", { text: "รวมจำนวนหลา : " + item.yard_total, alignment: "center", colSpan: 2 }, "", { text: "รวมจำนวนไม้ : " + item.wood_total, alignment: "center" }, { text: "ราคาสุทธิ : " + baht(item.net_total), alignment: "center" }]);
    array.push({
      table: {
        headerRows: 1,
        widths: ["20%", "20%", "10%", "10%", "10%", "15%", "15%"],
        body: [...table],
      },
    });
  }
  var docDefinition = {
    content: [
      {
        columns: [
          { width: 200, text: "", fontSize: 18, bold: true, color: "#4E6FFB" },
          { width: "*", text: "รายงานคืนสินค้า", alignment: "right", fontSize: 18, bold: true },
        ],
      },
      { text: " " },
      ...array,
      { text: " " },
      {
        columns: [
          { text: "รวมทั้งสิ้น", bold: true, fontSize: 10 },
          { text: baht(total(data, "net_total")) + " บาท", bold: true, fontSize: 10, alignment: "right" },
        ],
      },
    ],
    defaultStyle: { font: "FCIconic", fontSize: 7, lineHeight: 1 },
    pageSize: "A4",
    pageMargins: [15, 15, 15, 15],
  };
  print(docDefinition, "รายงานคืนสินค้า " + new Date().toISOString() + ".pdf");
}
function fieldSorter(fields) {
  return function (a, b) {
    return fields
      .map(function (o) {
        var dir = 1;
        if (o[0] === "-") {
          dir = -1;
          o = o.substring(1);
        }
        if (a[o] > b[o]) return dir;
        if (a[o] < b[o]) return -dir;
        return 0;
      })
      .reduce(function firstNonZeroValue(p, n) {
        return p ? p : n;
      }, 0);
  };
}
const toFixed = (number, size) => {
  if (number) {
    if (number === 0) {
      return "0.00";
    }
    if (size) {
      return Number(number)
        .toFixed(size)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    } else {
      return Number(number)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    }
  } else {
    return "0.00";
  }
};

function float(num) {
  if (/^\d*\.?\d{0,2}$/.test(num)) {
    return true;
  }
  return false;
}

function random() {
  var number = "";

  for (var i = 0; i < 6; i++) {
    number += Math.floor(Math.random() * 10).toString();
  }

  return number;
}
export {
  success,
  alert,
  alert_url,
  logout,
  GET,
  GET_CACHE,
  POST,
  POST_CACHE,
  PUT,
  PUT_CACHE,
  DELETE,
  DELETE_CACHE,
  format_date,
  format_date_sale,
  format_duedate_sale,
  date_overdue,
  format_date_main,
  format_date_notime,
  date,
  date_invoice,
  validate,
  gen_Password,
  fieldSorter,
  toFixed,
  cookies,
  tokens,
  role,
  ModalPrint,
  ModalDetails,
  ModalInstallment,
  ModalDetailsReceipt,
  ModalEditReceipt,
  ModalDetailsMoveStock,
  ModalInvoice,
  ModalInvoiceHistory,
  ModalDetailStatusDelivery,
  ModalDetailStatusDeliveryHistory,
  ModalSaleHistory,
  ModalDetailsLog,
  ModalDetailsReceiptLog,
  ModalDetailsRefundLog,
  ModalDetailsExpenses,
  ModalDetailsLog2,
  ModalDetailsReceiptLog2,
  ModalDetailsRefundLog2,
  status_stock,
  status_delivery,
  document_type,
  status_adjustment,
  status_sale_transaction,
  status_invoice,
  status_payment,
  status_type_expenses,
  status_refund,
  type_user,
  type_member,
  status_pos_payment,
  total,
  baht,
  number,
  financial,
  date_invoice_s,
  status_sale_transaction2,
  random,
  report_customer,
  report_products,
  report_inventory,
  report_products_invoice,
  report_transfer,
  report_1,
  report_2,
  report_3,
  report_4,
  report_5,
  report_6,
  print,
  float,
  report_expenses,
  report_refund,
  report_reciept,
  report_refund_byid,
};
